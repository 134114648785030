import React from "react";
import { Paper } from "@material-ui/core";
import FormatDate from "../../../../components/FormatDate";
import { useSelector } from "react-redux";
import "../../index.css";

const MessageCard = ({ message }) => {
  const loggedInUserEmail = useSelector((state) => state.isLogged.user.email);

  // const handleDownload = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const todownload = await fetch(
  //       `${process.env.REACT_APP_BASE_IMAGE_URL}/${message.attachement.imageName}`
  //     );
  //     const blob = await todownload.blob();
  //     const url = window.URL.createObjectURL(blob);

  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", message.attachement.imageName);

  //     // Append to html link element page
  //     document.body.appendChild(link);

  //     // Start download
  //     link.click();

  //     // Clean up and remove the link
  //     link.parentNode.removeChild(url);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <>
      <Paper
        className="message__card__paper"
        style={
          loggedInUserEmail === message.user.email
            ? { margin: "auto 0 20px auto" }
            : { margin: "auto auto 20px 0" }
        }
      >
        <p
          dangerouslySetInnerHTML={{
            __html: message.content.replaceAll("\n", "<br/>"),
          }}
        ></p>
        {message.attachement ? (
          <div className="message__card__attachement_container">
            <span>
              {message.attachement.imageName.length > 25
                ? message.attachement.imageName.substring(0, 20) +
                  "..." +
                  message.attachement.imageName.slice(-5)
                : message.attachement.imageName}
            </span>
            <a
              href={`${process.env.REACT_APP_BASE_IMAGE_URL}/${message.attachement.imageName}`}
              download={message.attachement.imageName}
              target="_blank"
              rel="noreferrer"
            >
              <span className="message__card__attachement__download__btn">
                <span>Télecharger</span>
              </span>
            </a>
          </div>
        ) : (
          ""
        )}
        <div className="message__card__info_container">
          <p align="left" className="message__card__user">
            {message.user.name}
          </p>
          <p align="right" className="message__card__date">
            <FormatDate date={message.createdAt} widthTime={true} />
          </p>
        </div>
      </Paper>
    </>
  );
};

export default MessageCard;
