import { useEffect, useState } from "react";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import API from "../../utils/API";
import "./main.css";

const Dashboard = () => {
  const [donorsNum, setDonorsNum] = useState(0);

  useEffect(() => {
    const getDashData = async () => {
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/dashboard`
        );

        if (res.code === 200) {
          setDonorsNum(res.donors);
        } else {
          alert("Une erreur est survenue, contactez l'équipe dev");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getDashData();
    return () => {
      setDonorsNum(0);
    };
  }, []);

  return (
    <>
      <h1>Dashboard</h1>
      <div className="main__container">
        <div className=""></div>
        <div className="card__container">
          <div className="icon__container">
            <SupervisorAccountIcon style={{ fontSize: "6em" }} />
          </div>
          <div className="number__container">{donorsNum}</div>
          <div className="text__container">Donateurs</div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
