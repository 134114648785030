import "./main.css";
import { useState, useEffect } from "react";
// redux
import { loggIn } from "../../providers/actions";

import { useDispatch, useSelector } from "react-redux";

import { TextField, Paper, Button, FormControl } from "@material-ui/core";

import API from "../../utils/API";
import Loading from "../../components/Loading";
const Login = ({ history }) => {
  const isLoggedIn = useSelector((state) => state.isLogged.isLoggedIn);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const getParameter = (key) => {
      // Address of the current window
      const address = window.location.search;

      // Returns a URLSearchParams object instance
      const parameterList = new URLSearchParams(address);

      // Returning the respected value associated
      // with the provided key
      return parameterList.get(key);
    };

    // Gets the value associated with the key "ie"
    const token = getParameter("redirecttoken");

    if (token && token.length > 0) {
      var base64Payload = token.split(".")[1];
      var payload = Buffer.from(base64Payload, "base64");
      const loggedinuser = JSON.parse(payload.toString());
      if (
        loggedinuser.roles.includes("ROLE_ADMIN") ||
        loggedinuser.roles.includes("ROLE_COMPTA") ||
        loggedinuser.roles.includes("ROLE_RD")
      ) {
        setIsLoading(false);
        dispatch(loggIn({ token: token }));
      } else {
        setIsLoading(false);
        alert("You are not authorized to access this app");
      }
    } else {
      setIsLoading(false);
    }

    if (isLoggedIn) {
      setTimeout(() => {
        window.location.href = "/";
      }, 50);
    }
  }, [isLoggedIn, dispatch, history]);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const pass = event.target.password.value;

    if (!email && !pass) {
      alert("You must enter your email and your password");
      return false;
    }

    setIsLoading(true);
    try {
      const response = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL_LOGIN}/login_check`,
        {
          username: email,
          password: pass,
        }
      );
      // return false;
      if (response.token) {
        var base64Payload = response.token.split(".")[1];
        var payload = Buffer.from(base64Payload, "base64");
        const loggedinuser = JSON.parse(payload.toString());
        if (loggedinuser.roles.includes("ROLE_ADMIN")) {
          dispatch(loggIn(response));
          setTimeout(() => {
            history.push("/");
          }, 2000);
        } else {
          window.location.href = "https://life-ong.org";
          return false;
        }
      } else if (response.code === 401) {
        //console.log("error", response);
        alert("Invalid Email or Password");
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert("Email or Password are invalid");
    }

    setIsLoading(false);
  };

  return (
    <div className="donate__loginPage__container">
      <Loading open={isLoading} />
      <Paper className="donate__login__paper">
        <h1 align="center">Login</h1>
        <form
          noValidate
          autoComplete="on"
          onSubmit={handleLoginSubmit}
          className="donate__login__form"
        >
          <TextField
            name="email"
            className="donate__login__el"
            type="email"
            label="Email"
          />
          <TextField
            name="password"
            className="donate__login__el"
            type="password"
            label="Password"
          />
          <FormControl>
            <Button variant="contained" color="primary" type="submit">
              Login
            </Button>
          </FormControl>
        </form>
      </Paper>
    </div>
  );
};

export default Login;
