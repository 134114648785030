import { useState } from "react";
import { Grid, Paper, TextField, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import Loading from "../../../components/Loading";
import DialogAlert from "../../../components/DialogAlert";

import API from "../../../utils/API";

const AddDonor = () => {
  const history = useHistory();

  const [loadingOpen, setLoadingOpen] = useState(false);
  const [alertmsg, setAlertmsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const handleAlertClose = (refresh) => {
    if (refresh) {
      setAlertOpen(false);
      history.push("/donors");
    }
    setAlertOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const donorName = e.target.donorName.value;
    const donorEmail = e.target.donorEmail.value;
    const donorPassword = e.target.donorPassword.value;
    const donorPasswordConf = e.target.donorPasswordConf.value;

    if (!donorName || !donorEmail || !donorPassword || !donorPasswordConf) {
      setAlertOpen(true);
      setAlertmsg("Tous les champs sont requis");
      return false;
    }

    try {
      setLoadingOpen(true);
      const res = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/donors`,
        {
          name: donorName,
          email: donorEmail,
          password: donorPassword,
        }
      );

      if (res.code === 200) {
        setLoadingOpen(false);
        setAlertOpen(true);
        setAlertmsg("Donateur ajouté avec succées");
      } else {
        setLoadingOpen(false);
        alert("Verifiez les infos entrées");
      }
    } catch (error) {
      setLoadingOpen(false);
    }
  };
  return (
    <>
      <DialogAlert
        open={alertOpen}
        msg={alertmsg}
        handleClose={handleAlertClose}
      />
      <Loading open={loadingOpen} />
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={8} md={6}>
          <h1>Ajouter un donateur</h1>
          <Paper className="paper__padding">
            <form onSubmit={handleFormSubmit}>
              <div className="form__control__custom">
                <TextField
                  id="donor_name"
                  name="donorName"
                  fullWidth
                  required
                  label="Nom du donateur"
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  type="email"
                  id="donor_email"
                  name="donorEmail"
                  fullWidth
                  required
                  label="Email"
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  type="password"
                  id="donor_password"
                  name="donorPassword"
                  fullWidth
                  required
                  label="Mot de passe"
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  type="password"
                  id="donor_passwordConf"
                  name="donorPasswordConf"
                  fullWidth
                  required
                  label="Confirmer le mot de passe"
                />
              </div>
              <div align="center">
                <Button variant="contained" color="primary" type="submit">
                  Ajouter
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddDonor;
