import React, { useEffect, useState } from "react";
import API from "../../utils/API";
import TicketCard from "./TicketCard";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";

const Contact = () => {
  const [data, setData] = useState([]);
  const [pagesTotalNum, setPagesTotalNum] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const pageNumCallback = (num) => {
    setPageNum(parseInt(num));
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoadingOpen(true);
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/ticket?pageNum=${pageNum}`
        );

        if (res.code === 200) {
          setData(res.data);
          setPagesTotalNum(Math.ceil(res.total / res.pageSize));
          setLoadingOpen(false);
        } else {
          alert("Une erreur est survenue, contactez l'équipe dev");
          setLoadingOpen(false);
        }
      } catch (error) {
        console.log(error);
        setLoadingOpen(false);
      }
    };
    getData();

    return () => {
      setData([]);
    };
  }, [pageNum]);
  return (
    <div>
      <h1>Contacts</h1>
      {loadingOpen ? (
        <Loading open={loadingOpen} />
      ) : data.length > 0 ? (
        data.map((ticket) => <TicketCard key={ticket.id} data={ticket} />)
      ) : (
        ""
      )}
      {data && pagesTotalNum > 1 ? (
        <Pagination
          pageNum={pageNum}
          pagesTotalNum={pagesTotalNum}
          pageNumCallback={pageNumCallback}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Contact;
