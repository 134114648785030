import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import API from "../../../utils/API";
import { Grid } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

import "./main.css";
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#ffffff",
  },
}))(LinearProgress);

const ShowCollectMonth = () => {
  const { id } = useParams();
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [collect, setCollect] = useState({
    name: "",
    description: "",
    banner: "",
    link: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingOpen(true);
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/collects-month/${id}`
        );

        if (res.code === 200) {
          const data = res.data;
          setCollect({
            name: data.name ?? "",
            description: data.description ?? "",
            link: data.link ?? "",
            banner: data.banner ?? "",
          });
          setLoadingOpen(false);
          setCollect(res.data);
        } else {
          setLoadingOpen(false);
          alert("Une erreur est survenue, contactez l'équipe dev");
        }
      } catch (error) {
        setLoadingOpen(false);
        console.log(error);
      }
    };
    fetchData();

    return () => {
      setCollect([]);
    };
  }, [id]);
  return (
    <>
      <Loading open={loadingOpen} />
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={8} md={8}>
          <h1>Preview de la collecte</h1>
          <div
            className="collect__month__container"
            style={
              collect.banner
                ? {
                    backgroundImage: `url('${process.env.REACT_APP_BASE_IMAGE_URL}/${collect.banner.imageName}')`,
                  }
                : { background: "white" }
            }
          >
            <div className="collect__month__info ">
              <h3 className="collect__month__title">{collect.name}</h3>
              <p className="collect__month__description">
                {collect.description}
              </p>
              <div>
                <p style={{ display: "inline-block", color: "white" }}>
                  <b>{collect.collected}</b> € collectés
                </p>
                <p
                  style={{
                    display: "inline-block",
                    float: "right",
                    color: "white",
                  }}
                >
                  Objectif <b>{collect.goal} €</b>
                </p>
              </div>
              <BorderLinearProgress
                variant="determinate"
                value={(collect.collected * 100) / collect.goal}
              />
            </div>
            <div className="collect__month__btn_container">
              <a
                className="collect__month__btn"
                href={collect.link}
                target="_blank"
                rel="noreferrer"
              >
                Je soutiens
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default ShowCollectMonth;
