import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useParams, useHistory } from "react-router";

import {
  FormControl,
  Input,
  InputLabel,
  IconButton,
  Button,
  InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

import API from "../../utils/API";
import ProjectCard from "./ProjectCard";
import SimplePagination from "../../components/SimplePagination";

const Projects = () => {
  let { page } = useParams();

  let history = useHistory();

  const [searchKey, setSearchKey] = useState("");
  const [resetSearch, setResetSearch] = useState(false);
  const [resetSearchVisible, setResetSearchVisible] = useState(false);

  const [projects, setProjects] = useState([]);

  const [pageNum, setPageNum] = useState(page ? page : 1);

  const [pagesTotal, setPagesTotal] = useState(1);

  const [reload, setReload] = useState(false);

  const reloadCallback = () => {
    setReload(!reload);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.has("query")) {
      setSearchKey(params.get("query"));
      history.push({
        pathname: "/projects",
        search: "?query=" + params.get("query"),
      });
      const initialSearch = async () => {
        try {
          const res = await API(
            "GET",
            `${
              process.env.REACT_APP_API_BASE_URL
            }/projects/search?key=${params.get("query")}`
          );

          if (res.code === 200) {
            setProjects(res.data);
            setResetSearchVisible(true);
          } else {
            console.log("error", res);
          }
        } catch (error) {
          console.log(error);
        }
      };
      initialSearch();
      return false;
    }
    (async () => {
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/projects?pageNum=${pageNum}`
        );
        if (res.code === 200) {
          setProjects(res.data);
          setPageNum(res.pageNum);
          setPagesTotal(Math.ceil(res.total / res.pageSize));
        }
      } catch (error) {
        console.log(error);
      }
    })();
    return () => {
      setProjects([]);
      setPagesTotal(1);
    };
  }, [pageNum, reload,history]);

  const handlePaginationCallback = (page) => {
    setPageNum(page);

    history.push(`/projects/${page}`);
  };

  const handleSearchClick = async (e) => {
    e.preventDefault();

    if (searchKey) {
      history.push({
        pathname: "/projects",
        search: "?query=" + searchKey,
      });
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/projects/search?key=${searchKey}`
        );

        if (res.code === 200) {
          setProjects(res.data);
          setResetSearchVisible(true);
        } else {
          console.log("error", res);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleResetSearchClick = () => {
    setResetSearch(!resetSearch);
    setResetSearchVisible(false);
    setPageNum(page);
  };

  return (
    <div>
      <h1>Projects</h1>
      <div
        style={{
          marginBottom: "20px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          alignItems: "center",
        }}
      >
        <div align="left">
          <Link to="/project/new" className="simple_link">
            <Button variant="contained" color="primary">
              Nouveau projet
            </Button>
          </Link>
        </div>

        <div align="right">
          <form onSubmit={handleSearchClick}>
            <FormControl>
              <InputLabel htmlFor="standard-adornment-search">
                Rechercher
              </InputLabel>
              <Input
                id="standard-adornment-search"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="Search" onClick={handleSearchClick}>
                      {<SearchIcon />}
                    </IconButton>
                    {resetSearchVisible ? (
                      <IconButton
                        aria-label="Reset Search"
                        onClick={handleResetSearchClick}
                      >
                        {<CloseIcon />}
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
        </div>
      </div>

      <div>
        {projects &&
          projects.map((p, index) => (
            <ProjectCard
              key={index}
              project={p}
              reloadCallback={reloadCallback}
            />
          ))}
      </div>
      <SimplePagination
        pageNum={pageNum}
        pagesTotal={pagesTotal}
        paginationCallback={handlePaginationCallback}
      />
    </div>
  );
};

export default Projects;
