import { Switch, Redirect } from "react-router-dom";

import RouteWithLayout from "./RouteWithLayout";
import DefaultLayout from "../layouts/DefaultLayout";
import NotFound from "../layouts/404";

import { Route } from "react-router-dom";

import Dashboard from "../views/Dashboard";
import Login from "../views/Login";
// import Settings from "../views/Settings";
import CollectMonth from "../views/CollectMonth";
import AddCollectMonth from "../views/CollectMonth/AddCollectMonth";
import NextEvent from "../views/NextEvent";
import EditCollectMonth from "../views/CollectMonth/EditCollectMonth";
import ShowCollectMonth from "../views/CollectMonth/ShowCollectMonth";
import AddNextEvent from "../views/NextEvent/AddNextEvent";
import EditNextEvent from "../views/NextEvent/EditNextEvent";
import ShowNextEvent from "../views/NextEvent/ShowNextEvent";
import Donors from "../views/Donors";
import AddDonor from "../views/Donors/AddDonor";
import EditDonor from "../views/Donors/EditDonor";
import ShowDonor from "../views/Donors/ShowDonor";
import Projects from "../views/Projects";
import AddProject from "../views/Projects/AddProject";
import EditProject from "../views/Projects/EditProject";
import Contact from "../views/Contact";
import ticketMessages from "../views/Contact/TiketMessages";

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={Dashboard}
        exact
        layout={DefaultLayout}
        path="/"
      />

      {/* Collect of the month */}
      <RouteWithLayout
        component={CollectMonth}
        layout={DefaultLayout}
        exact
        path="/collect-of-the-month"
      />
      <RouteWithLayout
        component={AddCollectMonth}
        layout={DefaultLayout}
        exact
        path="/collect-of-the-month/new"
      />
      <RouteWithLayout
        component={EditCollectMonth}
        layout={DefaultLayout}
        exact
        path="/collect-of-the-month/:id/update"
      />
      <RouteWithLayout
        component={ShowCollectMonth}
        layout={DefaultLayout}
        exact
        path="/collect-of-the-month/:id"
      />

      {/* Next Event */}
      <RouteWithLayout
        component={NextEvent}
        layout={DefaultLayout}
        exact
        path="/next-event"
      />
      <RouteWithLayout
        component={AddNextEvent}
        layout={DefaultLayout}
        exact
        path="/next-event/new"
      />
      <RouteWithLayout
        component={EditNextEvent}
        layout={DefaultLayout}
        exact
        path="/next-event/:id/update"
      />
      <RouteWithLayout
        component={ShowNextEvent}
        layout={DefaultLayout}
        exact
        path="/next-event/:id"
      />

      <RouteWithLayout
        component={Projects}
        layout={DefaultLayout}
        exact
        path="/projects/:page?"
      />
      <RouteWithLayout
        component={AddProject}
        layout={DefaultLayout}
        exact
        path="/project/new"
      />
      <RouteWithLayout
        component={EditProject}
        layout={DefaultLayout}
        exact
        path="/projects/:id/update"
      />

      {/* Donors */}
      <RouteWithLayout
        component={Donors}
        layout={DefaultLayout}
        exact
        path="/donors/:page?"
      />
      <RouteWithLayout
        component={AddDonor}
        layout={DefaultLayout}
        exact
        path="/donors/new"
      />
      <RouteWithLayout
        component={EditDonor}
        layout={DefaultLayout}
        exact
        path="/donors/:id/update"
      />
      <RouteWithLayout
        component={ShowDonor}
        layout={DefaultLayout}
        exact
        path="/donors/:id/details"
      />

      <RouteWithLayout
        component={Contact}
        layout={DefaultLayout}
        exact
        path="/contact-requests"
      />
      <RouteWithLayout
        component={ticketMessages}
        layout={DefaultLayout}
        exact
        path="/contact-requests/:id"
      />

      <Route component={NotFound} exact path="/not-found" />
      <Route component={Login} exact path="/login" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
