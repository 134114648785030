import { useState } from "react";
import { Grid, Paper, TextField, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "./main.css";

import Loading from "../../../components/Loading";
import DialogAlert from "../../../components/DialogAlert";

import emptyImage from "../../../assets/img/empty-image.png";
import API from "../../../utils/API";

const AddCollectMonth = () => {
  const [collectBanner, setCollectBanner] = useState(emptyImage);
  const [collectBannerObject, setCollectBannerObject] = useState();
  const [alertmsg, setAlertmsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const handleAlertClose = (refresh) => {
    if (refresh) {
      setAlertOpen(false);
      history.push("/collect-of-the-month");
    }
    setAlertOpen(false);
  };
  const [loadingOpen, setLoadingOpen] = useState(false);
  const history = useHistory();

  const handleBannerChange = (el) => {
    if (el.target.files[0]) {
      const blobURL = URL.createObjectURL(el.target.files[0]);
      setCollectBanner(blobURL);
      setCollectBannerObject(el.target.files[0]);
    } else {
      setCollectBanner(emptyImage);
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const collectName = e.target.collectName.value;
    const collectDec = e.target.collectDec.value;
    const collectLink = e.target.collectLink.value;
    const collectCollected = e.target.collectCollected.value;
    const collectGoal = e.target.collectGoal.value;

    if (
      !collectName ||
      !collectDec ||
      !collectLink ||
      !collectCollected ||
      !collectGoal
    ) {
      setAlertOpen(true);
      setAlertmsg("Tous les champs sont requis");
      return false;
    }

    try {
      setLoadingOpen(true);
      var fd = new FormData();
      fd.append("name", collectName);
      fd.append("description", collectDec);
      fd.append("link", collectLink);
      fd.append("banner", collectBannerObject);
      fd.append("collected", collectCollected);
      fd.append("goal", collectGoal);

      const res = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/collects-month`,
        fd,
        "FormData"
      );

      if (res.code === 200) {
        setLoadingOpen(false);
        setAlertOpen(true);
        setAlertmsg("Collect ajouté avec succées");
      } else {
        setLoadingOpen(false);
        alert("Verifiez les infos entrées");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <DialogAlert
        open={alertOpen}
        msg={alertmsg}
        handleClose={handleAlertClose}
      />
      <Loading open={loadingOpen} />
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={8} md={6}>
          <h1>Ajouter collecte du mois</h1>
          <Paper className="paper__padding">
            <form onSubmit={handleFormSubmit}>
              <div className="form__control__custom">
                <TextField
                  id="collect_name"
                  name="collectName"
                  fullWidth
                  required
                  label="Nom de la collecte"
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="collect_description"
                  name="collectDec"
                  label="Description de la collecte"
                  fullWidth
                  multiline
                  rows={4}
                  required
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="collect_month_link"
                  name="collectLink"
                  label="Lien de la collecte"
                  fullWidth
                  required
                />
              </div>
              <div className="form__control__custom">
                <div className="custom__col2">
                  <TextField
                    type="number"
                    id="collect_month_collected"
                    name="collectCollected"
                    label="La somme collectée  (€)"
                    fullWidth
                    required
                  />
                </div>
                <div className="custom__col2">
                  <TextField
                    type="number"
                    id="collect_month_goal"
                    name="collectGoal"
                    label="Objectif de la collecte (€)"
                    fullWidth
                    required
                  />
                </div>
              </div>
              <div className="form__control__custom">
                <label align="center" className="image__preview__container">
                  <input
                    type="file"
                    name="cover"
                    hidden
                    onChange={handleBannerChange}
                  />
                  <img
                    src={collectBanner}
                    alt="preview"
                    className="image__preview"
                  />
                </label>

                {/* <label htmlFor="collect_cover" id="collect_cover_label">
                  Banniere de la Collecte
                  <input type="file" id="collect_cover" name="collectCover" />
                </label> */}
              </div>
              <div align="center">
                <Button variant="contained" color="primary" type="submit">
                  Enregistrer
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddCollectMonth;
