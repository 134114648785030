import React from "react";

import { Button } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import "./main.css";

const Pagination = ({ pageNum, pagesTotalNum, pageNumCallback }) => {
  const handlePrevClick = () => {
    if (pageNum > 1) {
      pageNumCallback(pageNum - 1);
    }
  };
  const handleNextClick = () => {
    if (pageNum < pagesTotalNum) {
      pageNumCallback(pageNum + 1);
    }
  };
  const handleInputChange = (e) => {
    if (e.target.value <= pagesTotalNum && e.target.value >= 1)
      pageNumCallback(e.target.value);
  };
  return (
    <div className="pagination__container">
      <div>
        <Button
          startIcon={<ChevronLeftIcon />}
          variant="contained"
          color="secondary"
          size="small"
          onClick={handlePrevClick}
          disabled={pageNum === 1 ? true : false}
        >
          Préc
        </Button>
      </div>
      <div>
        <input
          type="number"
          value={pageNum}
          onChange={handleInputChange}
          style={{ width: "50px" }}
        />
        <span style={{ fontSize: " 15px", fontWeight: "bold" }}>
          {" "}
          / {pagesTotalNum}
        </span>
      </div>
      <div>
        <Button
          endIcon={<ChevronRightIcon />}
          variant="contained"
          color="secondary"
          size="small"
          onClick={handleNextClick}
          disabled={pageNum === pagesTotalNum ? true : false}
        >
          Suiv
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
