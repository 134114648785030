import { Paper } from "@material-ui/core";
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import Loading from "../../../components/Loading";

import API from "../../../utils/API";

const ShowDonor = () => {
  const { id } = useParams();

  const [loadingOpen, setLoadingOpen] = useState(false);
  const [donor, setDonor] = useState({
    name: "",
    email: "",
    password: "",
    passwordConfirm: "",

    fullName: "",
    firstName: "",
    lastName: "",
    address: "",
    country: "",
    city: "",
    zipCode: "",
    phoneNumber: "",
    company: "",
    state: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingOpen(true);
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/donors/${id}`
        );

        if (res.code === 200) {
          const data = res.data;

          setDonor({
            name: data.name,
            email: data.email,

            fullName: data.donor.fullName ? data.donor.fullName : "",
            firstName: data.donor.firstName ? data.donor.firstName : "",
            lastName: data.donor.lastName ? data.donor.lastName : "",
            address: data.donor.address ? data.donor.address : "",
            country: data.donor.country ? data.donor.country : "",
            city: data.donor.city ? data.donor.city : "",
            zipCode: data.donor.zipCode ? data.donor.zipCode : "",
            phoneNumber: data.donor.phoneNumber ? data.donor.phoneNumber : "",
            company: data.donor.company ? data.donor.company : "",
            state: data.donor.state ? data.donor.state : "",
          });
          setLoadingOpen(false);
        } else {
          setLoadingOpen(false);
          alert("Une erreur est survenue, contactez l'équipe dev");
        }
      } catch (error) {
        setLoadingOpen(false);
        console.log(error);
      }
    };
    fetchData();
    return () => {
      setDonor({
        name: "",
        email: "",

        fullName: "",
        firstName: "",
        lastName: "",
        address: "",
        country: "",
        city: "",
        zipCode: "",
        phoneNumber: "",
        company: "",
        state: "",
      });
    };
  }, [id]);

  return (
    <div>
      <Loading open={loadingOpen} />
      <h1>Détails donateur</h1>
      <div>
        <Paper style={{ padding: "20px", marginBottom: "20px" }}>
          <p>
            <b>Email: </b>
            {donor.email}
          </p>
          <p>
            <b>Nom complet:</b> {donor.fullName}
          </p>
          <p>
            <b>Numéro telephone:</b> {donor.phoneNumber}
          </p>
        </Paper>
      </div>
      <div>
        <Paper style={{ padding: "20px" }}>
          <p>
            <b>Adresse:</b> {donor.address}
          </p>
          <p>
            <b>Pays:</b> {donor.country}
          </p>
          <p>
            <b>Ville:</b> {donor.city}
          </p>
          <p>
            <b>Code postal:</b> {donor.zipCode}
          </p>
          <p>
            <b>Région:</b> {donor.state}
          </p>
          <p>
            <b>Raison sociale:</b> {donor.company}
          </p>
        </Paper>
      </div>
    </div>
  );
};

export default ShowDonor;
