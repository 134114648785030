import { useState } from "react";
import { Grid, Paper, TextField, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import Loading from "../../../components/Loading";
import DialogAlert from "../../../components/DialogAlert";

import emptyImage from "../../../assets/img/empty-image.png";
import API from "../../../utils/API";

const AddNextEvent = () => {
  const history = useHistory();

  const [loadingOpen, setLoadingOpen] = useState(false);
  const [eventBanner, setEventBanner] = useState(emptyImage);
  const [eventBannerObject, setEventBannerObject] = useState();
  const [alertmsg, setAlertmsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const handleAlertClose = (refresh) => {
    if (refresh) {
      setAlertOpen(false);
      history.push("/next-event");
    }
    setAlertOpen(false);
  };

  const handleBannerChange = (el) => {
    if (el.target.files[0]) {
      const blobURL = URL.createObjectURL(el.target.files[0]);
      setEventBanner(blobURL);
      setEventBannerObject(el.target.files[0]);
    } else {
      setEventBanner(emptyImage);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const eventTitle = e.target.eventTitle.value;
    const eventSlogan = e.target.eventSlogan.value;
    const eventColorHexa = e.target.eventColorHexa.value;
    const eventLink = e.target.eventLink.value;
    const eventCampaign = e.target.eventCampaign.value;

    if (!eventLink || !eventCampaign) {
      setAlertOpen(true);
      setAlertmsg("Tous les champs sont nécessaires");
      return false;
    }

    try {
      setLoadingOpen(true);
      var fd = new FormData();
      fd.append("title", eventTitle);
      fd.append("slogan", eventSlogan);
      fd.append("colorHexa", eventColorHexa);
      fd.append("link", eventLink);
      fd.append("campaign", eventCampaign);
      fd.append("banner", eventBannerObject);

      const res = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/next-events`,
        fd,
        "FormData"
      );

      if (res.code === 200) {
        setLoadingOpen(false);
        setAlertOpen(true);
        setAlertmsg("évenement ajouté avec succées");
      } else {
        setLoadingOpen(false);
        alert("Verifiez les infos entrés");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <DialogAlert
        open={alertOpen}
        msg={alertmsg}
        handleClose={handleAlertClose}
      />
      <Loading open={loadingOpen} />
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={8} md={6}>
          <h1>Ajouter le prochain évenement</h1>
          <Paper className="paper__padding">
            <form onSubmit={handleFormSubmit}>
              <div className="form__control__custom">
                <TextField
                  id="event_title"
                  name="eventTitle"
                  fullWidth
                  label="Titre de l'évenement"
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="event_slogan"
                  name="eventSlogan"
                  fullWidth
                  label="Slogan"
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="event_colorHexa"
                  name="eventColorHexa"
                  fullWidth
                  label="Couleur Hexadecimal ex: #ffffff"
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="event_campaign"
                  name="eventCampaign"
                  label="CTA"
                  fullWidth
                  required
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="event_link"
                  name="eventLink"
                  label="Lien de l'évenement"
                  fullWidth
                  required
                />
              </div>

              <div className="form__control__custom">
                <label align="center" className="image__preview__container">
                  <input
                    type="file"
                    name="cover"
                    hidden
                    onChange={handleBannerChange}
                  />
                  <img
                    src={eventBanner}
                    alt="preview"
                    className="image__preview"
                  />
                </label>
              </div>

              <div align="center">
                <Button variant="contained" color="primary" type="submit">
                  Enregistrer
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddNextEvent;
