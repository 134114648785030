import { useState, useEffect } from "react";
import { Grid, Paper, TextField, Button } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

import Loading from "../../../components/Loading";
import DialogAlert from "../../../components/DialogAlert";

import emptyImage from "../../../assets/img/empty-image.png";
import API from "../../../utils/API";

const EditNextEvent = () => {
  const { id } = useParams();
  const history = useHistory();

  const [event, setEvent] = useState({
    title: "",
    link: "",
    campaign: "",
    banner: emptyImage,
  });
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [eventBannerObject, setEventBannerObject] = useState();
  const [alertmsg, setAlertmsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingOpen(true);
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/next-events/${id}`
        );

        if (res.code === 200) {
          const data = res.data;

          setLoadingOpen(false);
          setEvent({
            title: data.title ?? "",
            campaign: data.campaign ?? "",
            colorHexa: data.colorHexa ?? "",
            slogan: data.slogan ?? "",
            link: data.link ?? "",
            banner:
              process.env.REACT_APP_BASE_IMAGE_URL +
              "/" +
              data.banner.imageName,
          });
        } else {
          setLoadingOpen(false);
          alert("Une erreur est survenue, contactez l'équipe dev");
        }
      } catch (error) {
        setLoadingOpen(false);
        console.log(error);
      }
    };
    fetchData();
    return () => {
      setEvent({});
    };
  }, [id]);

  const handleAlertClose = (refresh) => {
    if (refresh) {
      setAlertOpen(false);
      history.push("/next-event");
    }
    setAlertOpen(false);
  };

  const handleBannerChange = (el) => {
    if (el.target.files[0]) {
      const blobURL = URL.createObjectURL(el.target.files[0]);
      setEventBannerObject(el.target.files[0]);
      setEvent({ ...event, banner: blobURL });
    } else {
      setEvent({ ...event, banner: emptyImage });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const eventTitle = e.target.eventTitle.value;
    const eventSlogan = e.target.eventSlogan.value;
    const eventColorHexa = e.target.eventColorHexa.value;
    const eventLink = e.target.eventLink.value;
    const eventCampaign = e.target.eventCampaign.value;

    if (!eventLink || !eventCampaign) {
      setAlertOpen(true);
      setAlertmsg("Le lien et la campagne sont nécessaires");
      return false;
    }

    try {
      setLoadingOpen(true);
      var fd = new FormData();
      fd.append("title", eventTitle);
      fd.append("slogan", eventSlogan);
      fd.append("colorHexa", eventColorHexa);
      fd.append("link", eventLink);
      fd.append("campaign", eventCampaign);
      fd.append("banner", eventBannerObject);

      const res = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/next-events/${id}`,
        fd,
        "FormData"
      );

      if (res.code === 200) {
        setLoadingOpen(false);
        setAlertOpen(true);
        setAlertmsg("Evenement Modifié avec succées");
      } else {
        setLoadingOpen(false);
        alert("Verifiez les infos entrés");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormChange = (e, input) => {
    if (input === "title") {
      setEvent({ ...event, title: e.target.value });
    } else if (input === "link") {
      setEvent({ ...event, link: e.target.value });
    } else if (input === "campaign") {
      setEvent({ ...event, campaign: e.target.value });
    } else if (input === "slogan") {
      setEvent({ ...event, slogan: e.target.value });
    } else if (input === "colorHexa") {
      setEvent({ ...event, colorHexa: e.target.value });
    }
  };

  return (
    <>
      <DialogAlert
        open={alertOpen}
        msg={alertmsg}
        handleClose={handleAlertClose}
      />
      <Loading open={loadingOpen} />
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={8} md={6}>
          <h1>Editer le prochain évenement</h1>
          <Paper className="paper__padding">
            <form onSubmit={handleFormSubmit}>
              <div className="form__control__custom">
                <TextField
                  id="event_title"
                  name="eventTitle"
                  fullWidth
                  label="Titre de l'évenement"
                  value={event.title}
                  onChange={(e) => {
                    handleFormChange(e, "title");
                  }}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="event_slogan"
                  name="eventSlogan"
                  fullWidth
                  label="Slogan"
                  value={event.slogan}
                  onChange={(e) => {
                    handleFormChange(e, "slogan");
                  }}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="event_colorHexa"
                  name="eventColorHexa"
                  fullWidth
                  label="Couleur Hexadecimal ex: #ffffff"
                  value={event.colorHexa}
                  onChange={(e) => {
                    handleFormChange(e, "colorHexa");
                  }}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="event_campaign"
                  name="eventCampaign"
                  label="CTA"
                  fullWidth
                  required
                  value={event.campaign}
                  onChange={(e) => {
                    handleFormChange(e, "campaign");
                  }}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="event_link"
                  name="eventLink"
                  label="Lien de l'évenement"
                  fullWidth
                  required
                  value={event.link}
                  onChange={(e) => {
                    handleFormChange(e, "link");
                  }}
                />
              </div>

              <div className="form__control__custom">
                <label align="center" className="image__preview__container">
                  <input
                    type="file"
                    name="cover"
                    hidden
                    onChange={handleBannerChange}
                  />
                  <img
                    src={event.banner}
                    alt="preview"
                    className="image__preview"
                  />
                </label>
              </div>

              <div align="center">
                <Button variant="contained" color="primary" type="submit">
                  Enregistrer
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default EditNextEvent;
