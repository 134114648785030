import React, { useState, useEffect } from "react";
import MessageCard from "./MessageCard";
import { useParams } from "react-router";
import API from "../../../utils/API";
import Loading from "../../../components/Loading";
// import { TextField, Button, FormControlLabel, Switch } from "@material-ui/core";
import { FormControlLabel, Switch } from "@material-ui/core";

const TicketMessages = () => {
  const { id } = useParams();

  const [messages, setMessages] = useState([]);
  const [reload, setReload] = useState(false);
  const [loadingOpen, setLoadingOpen] = useState(false);

  const [resolu, setresolu] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoadingOpen(true);
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/ticket/${id}`
        );

        if (res.code === 200) {
          if (res.data.status === "Résolu") {
            setresolu(true);
          } else {
            setresolu(false);
          }
          setMessages(res.data.message);
          setLoadingOpen(false);
        } else {
          alert("Une erreur est survenue, contactez l'équipe dev");
          setLoadingOpen(false);
        }
      } catch (error) {
        console.log(error);
        setLoadingOpen(false);
      }
    };
    getData();

    return () => {
      setMessages();
    };
  }, [reload, id]);

  // const handleSubmitReply = async (e) => {
  //   e.preventDefault();
  //   const repMessage = e.target.replyMessage.value;

  //   if (!repMessage || repMessage.length < 5) {
  //     alert("Vous devez remplir le champ réponse");
  //   }

  //   // TODO: set new message with ticket id
  //   try {
  //     setLoadingOpen(true);
  //     const res = await API(
  //       "POST",
  //       `${process.env.REACT_APP_API_BASE_URL}/message`,
  //       { content: repMessage, ticketId: id }
  //     );

  //     if (res.code === 200) {
  //       setReload(true);
  //       setLoadingOpen(false);
  //       e.target.reset();
  //     } else {
  //       alert("Une erreur est survenue, contactez l'équipe dev");
  //       setLoadingOpen(false);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }

  //   setReload(true);
  // };

  const handleChangeRsolut = async () => {
    const confir = window.confirm(
      !resolu
        ? "Vous voulez vraiment marqué ce ticket en résolu?"
        : "Vous voulez réouvrir ce ticket?"
    );
    if (!confir) {
      return false;
    }

    try {
      setLoadingOpen(true);
      const res = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/ticket/${id}`,
        { status: !resolu ? "Résolu" : "Ouvert" }
      );

      if (res.code === 200) {
        setresolu(!resolu);
        setReload(true);
        setLoadingOpen(false);
      } else {
        alert("Une erreur est survenue, contactez l'équipe dev");
        setLoadingOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div align="right">
        <FormControlLabel
          label="Résolu"
          labelPlacement="end"
          control={
            <Switch
              checked={resolu}
              onChange={handleChangeRsolut}
              name="resolu"
              color="primary"
              disabled
            />
          }
        />
      </div>

      <div className="messages__container">
        {loadingOpen ? (
          <Loading open={loadingOpen} />
        ) : messages && messages.length > 0 ? (
          messages.map((message) => (
            <MessageCard key={message.id} message={message} />
          ))
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TicketMessages;
