import "./App.css";
import Routes from "./routes/Routes";
// import Checkout from "./views/Chekout";

import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();

const App = () => {
  const palletType = "light";
  const theme = createTheme({
    palette: {
      type: palletType,
      primary: {
        light: "#ff9f50",
        main: "#f36e20",
        dark: "#b93e00",
        contrastText: "#fff",
      },
      secondary: {
        light: "#6d6d6d",
        main: "#424242",
        dark: "#1b1b1b",
        contrastText: "#ffffff",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </ThemeProvider>
  );
};

export default App;
