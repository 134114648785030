import { useState, useEffect } from "react";
import { Grid, Paper, TextField, Button, FormControl } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

import Loading from "../../../components/Loading";
import DialogAlert from "../../../components/DialogAlert";

import emptyImage from "../../../assets/img/empty-image.png";
import API from "../../../utils/API";

const EditCollectMonth = () => {
  const { id } = useParams();
  const history = useHistory();

  const [collectBannerObject, setCollectBannerObject] = useState();
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [alertmsg, setAlertmsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const handleAlertClose = (refresh) => {
    if (refresh) {
      setAlertOpen(false);
      history.push("/collect-of-the-month");
    }
    setAlertOpen(false);
  };

  const [collect, setCollect] = useState({
    name: "",
    description: "",
    link: "",
    banner: emptyImage,
  });

  const handleBannerChange = (el) => {
    if (el.target.files[0]) {
      const blobURL = URL.createObjectURL(el.target.files[0]);
      setCollectBannerObject(el.target.files[0]);
      setCollect({ ...collect, banner: blobURL });
    } else {
      setCollect({ ...collect, banner: emptyImage });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingOpen(true);
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/collects-month/${id}`
        );

        if (res.code === 200) {
          const data = res.data;

          setLoadingOpen(false);
          setCollect({
            name: data.name ?? "",
            description: data.description ?? "",
            link: data.link ?? "",
            collected: data.collected ?? "",
            goal: data.goal ?? "",
            banner:
              process.env.REACT_APP_BASE_IMAGE_URL +
              "/" +
              data.banner.imageName,
          });
        } else {
          setLoadingOpen(false);
          alert("Une erreur est survenue, contactez l'équipe dev");
        }
      } catch (error) {
        setLoadingOpen(false);
        console.log(error);
      }
    };
    fetchData();

    return () => {
      setCollect([]);
    };
  }, [id]);

  const handleNameChange = (e) => {
    setCollect({ ...collect, name: e.target.value });
  };
  const handleDescChange = (e) => {
    setCollect({ ...collect, description: e.target.value });
  };
  const handleLinkChange = (e) => {
    setCollect({ ...collect, link: e.target.value });
  };
  const handleCollectedChange = (e) => {
    setCollect({ ...collect, collected: e.target.value });
  };
  const handleGoalChange = (e) => {
    setCollect({ ...collect, goal: e.target.value });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const collectName = e.target.collectName.value;
    const collectDec = e.target.collectDec.value;
    const collectLink = e.target.collectLink.value;
    const collectCollected = e.target.collectCollected.value;
    const collectGoal = e.target.collectGoal.value;

    if (
      !collectName ||
      !collectDec ||
      !collectLink ||
      !collectCollected ||
      !collectGoal
    ) {
      alert("Tous les champs sont nécessaires");
      return false;
    }

    var fd = new FormData();
    fd.append("name", collectName);
    fd.append("description", collectDec);
    fd.append("link", collectLink);
    fd.append("collected", collectCollected);
    fd.append("goal", collectGoal);
    if (collectBannerObject) fd.append("banner", collectBannerObject);

    try {
      setLoadingOpen(true);
      const res = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/collects-month/${id}`,
        fd,
        "FormData"
      );

      if (res.code === 200) {
        setLoadingOpen(false);
        setAlertOpen(true);
        setAlertmsg("Collecte modifiée avec succées");
      } else {
        setLoadingOpen(false);
        setAlertOpen(false);
        setAlertmsg("Une erreur est survenue");
      }
    } catch (error) {
      console.log(error);
    }
    // send data here
  };
  return (
    <>
      <DialogAlert
        open={alertOpen}
        msg={alertmsg}
        handleClose={handleAlertClose}
      />
      <Loading open={loadingOpen} />
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={8} md={6}>
          <h1>Modifier collecte du mois</h1>
          <Paper className="paper__padding">
            <form onSubmit={handleFormSubmit}>
              <div className="form__control__custom">
                <TextField
                  id="collect_name"
                  name="collectName"
                  fullWidth
                  required
                  value={collect.name}
                  onChange={handleNameChange}
                  label="Nom de la collecte"
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="collect_description"
                  name="collectDec"
                  label="Description de la collecte"
                  fullWidth
                  multiline
                  rows={4}
                  required
                  value={collect.description}
                  onChange={handleDescChange}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="collect_month_link"
                  name="collectLink"
                  label="Lien de la collecte"
                  fullWidth
                  required
                  value={collect.link}
                  onChange={handleLinkChange}
                />
              </div>
              <div className="form__control__custom">
                <FormControl className="custom__col2">
                  <TextField
                    type="number"
                    id="collect_month_collected"
                    name="collectCollected"
                    label="La somme collectée  (€)"
                    value={collect.collected}
                    onChange={handleCollectedChange}
                    fullWidth
                    required
                  />
                </FormControl>
                <FormControl className="custom__col2">
                  <TextField
                    type="number"
                    id="collect_month_goal"
                    name="collectGoal"
                    label="Objectif de la collecte (€)"
                    value={collect.goal}
                    onChange={handleGoalChange}
                    fullWidth
                    required
                  />
                </FormControl>
              </div>
              <div className="form__control__custom">
                <label align="center" className="image__preview__container">
                  <input
                    type="file"
                    name="banner"
                    hidden
                    onChange={handleBannerChange}
                  />
                  <img
                    src={collect.banner}
                    alt="preview"
                    className="image__preview"
                  />
                </label>

                {/* <label htmlFor="collect_cover" id="collect_cover_label">
                  Banniere de la Collecte
                  <input type="file" id="collect_cover" name="collectCover" />
                </label> */}
              </div>
              <div align="center">
                <Button variant="contained" color="primary" type="submit">
                  Enregistrer
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default EditCollectMonth;
