import React from "react";
import { Paper } from "@material-ui/core";
import "../index.css";
import { useHistory } from "react-router-dom";
import FormatDate from "../../../components/FormatDate";
import VisibilityIcon from "@material-ui/icons/Visibility";

const TicketCard = ({ data }) => {
  let history = useHistory();
  const handleLink = (e) => {
    e.preventDefault();
    history.push(`/contact-requests/${data.id}`);
  };
  return (
    <>
      <Paper style={{ padding: " 10px 20px", marginBottom: "20px" }}>
        <div className="ticket__card__container">
          <span className="">#{data.id}</span>
          <span>{data.object}</span>
          <span>{data.user ? data.user.name : ""}</span>
          <span className="ticket__card__el_center ">
            <span
              className={
                data.status === "Résolu"
                  ? "ticket__card__el_badge badge_closed"
                  : "ticket__card__el_badge badge_open"
              }
            >
              {data.status}
            </span>
          </span>
          <span className="ticket__card__el_center">
            <FormatDate date={data.createdAt} />
          </span>
          <span className="ticket__card__el_center">
            <a
              href={`/contact-requests/${data.id}`}
              onClick={handleLink}
              title="Détails"
            >
              <VisibilityIcon />
            </a>
          </span>
        </div>
      </Paper>
    </>
  );
};

export default TicketCard;
