import { useState, useEffect } from "react";
import { useParams } from "react-router";
import API from "../../../utils/API";
import Form from "../Form";

const EditProject = () => {
  const { id } = useParams();
  const [projectData, setProjectData] = useState({
    projectId: "",
    projectName: "",
    projectDescription: "",
    projectImage: "",
    projectAmount: "",
    projectStatus: "",
    endDate: null,
    projectStep: "",
    projectType: "",
    projectFinancement: "",
    wellDepth: "",
    projectLat: "",
    projectLong: "",
    projectTown: "",
    donorNamePlate: "",
    wellNamePlate: "",
    beneficiariesNumber: "",
    donorId: "",
    mediaFolderUrl: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/projects/${id}`
        );
        if (response.code === 200) {
          var projectMedias = [];

          response.data.medias.forEach((element) => {
            projectMedias.push({
              id: element.id,
              url:
                process.env.REACT_APP_BASE_IMAGE_URL + "/" + element.imageName,
            });
          });

          setProjectData({
            projectId: response.data.id ? response.data.id : "",
            projectName: response.data.name ? response.data.name : "",
            projectDescription: response.data.description
              ? response.data.description
              : "",
            projectImage: response.data.photo
              ? process.env.REACT_APP_BASE_IMAGE_URL +
                "/" +
                response.data.photo.imageName
              : "",
            projectLivret: response.data.livret
              ? process.env.REACT_APP_BASE_IMAGE_URL +
                "/" +
                response.data.livret.imageName
              : "",
            projectAmount: response.data.amountGoal
              ? response.data.amountGoal
              : "",
            projectStatus: response.data.projectStatus
              ? response.data.projectStatus
              : "",
            endDate: response.data.endDate
              ? new Date(response.data.endDate)
              : null,
            projectStep: response.data.projectStep
              ? response.data.projectStep
              : "",
            projectType: response.data.type ? response.data.type : "",
            projectFinancement: response.data.financementType
              ? response.data.financementType
              : "",
            wellDepth: response.data.projectDepth
              ? response.data.projectDepth
              : "",
            projectLat: response.data.locationLat
              ? response.data.locationLat
              : "",
            projectLong: response.data.locationLong
              ? response.data.locationLong
              : "",
            projectTown: response.data.town ? response.data.town : "",
            donorNamePlate: response.data.plateDonorName
              ? response.data.plateDonorName
              : "",
            wellNamePlate: response.data.plateProjectName
              ? response.data.plateProjectName
              : "",
            beneficiariesNumber: response.data.beneficiairesNumber
              ? response.data.beneficiairesNumber
              : "",
            projectMedias: projectMedias ? projectMedias : "",
            mediaFolderUrl: response.data.mediaFolderUrl
              ? response.data.mediaFolderUrl
              : "",
            donorId: response.data.user
              ? {
                  id: response.data.user.id,
                  name: response.data.user.name,
                  email: response.data.user.email,
                }
              : "",
          });
        }
      } catch (error) {
        console.log(error);
      }
    })();
    return () => {
      setProjectData({
        projectId: "",
        projectName: "",
        projectObjectif: "",
        projectStatus: "",
        endDate: null,
        projectStep: "",
        projectType: "",
        projectFinancement: "",
        collectedAmount: "",
        wellDepth: "",
        projectLat: "",
        projectLong: "",
        projectTown: "",
        donorNamePlate: "",
        wellNamePlate: "",
        beneficiariesNumber: "",
        donorId: "",
        mediaFolderUrl: "",
      });
    };
  }, [id]);

  return (
    <>
      <Form pageTitle={"Edit Projet"} projectData={projectData} />
    </>
  );
};

export default EditProject;
