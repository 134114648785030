const API = async (method, url, data, type) => {
  var authorization = "";
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    authorization = user.token;
  }

  var headers = {};
  var boyContent = {};
  if (type === "FormData") {
    headers = {
      Authorization: "bearer " + authorization,
    };
    boyContent = data;
  } else {
    headers = {
      Authorization: "bearer " + authorization,
      "content-Type": "application/json",
    };
    boyContent = JSON.stringify(data);
  }

  try {
    const fetchapi = await fetch(url, {
      method: method,
      headers: headers,
      body: boyContent,
    });
    const fetchapiJson = await fetchapi.json();

    if (
      fetchapiJson.code === 401 &&
      fetchapiJson.message === "Expired JWT Token"
    ) {
      localStorage.removeItem("expiration");
      localStorage.removeItem("user");
      window.location.href = "/login";
      return false;
    }
    return fetchapiJson;
  } catch (error) {
    console.log(error);
    // localStorage.removeItem("expiration");
    // localStorage.removeItem("user");
    // window.location.href = "/login";
    return false;
  }
};
export default API;
