import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import API from "../../../utils/API";
import { Grid } from "@material-ui/core";
import "../main.css";

const ShowNextEvent = () => {
  const { id } = useParams();
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [event, setEvent] = useState({
    name: "",
    campaign: "",
    banner: "",
    link: "",
    slogan: "",
    colorHexa: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingOpen(true);
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/next-events/${id}`
        );

        if (res.code === 200) {
          const data = res.data;
          setEvent({
            title: data.title ?? "",
            link: data.link ?? "",
            campaign: data.campaign ?? "",
            banner: data.banner ?? "",
            slogan: data.slogan ?? "",
            colorHexa: data.colorHexa ?? "",
          });
          setLoadingOpen(false);
        } else {
          setLoadingOpen(false);
          alert("Une erreur est survenue, contactez l'équipe dev");
        }
      } catch (error) {
        setLoadingOpen(false);
        console.log(error);
      }
    };
    fetchData();

    return () => {
      setEvent([]);
    };
  }, [id]);

  return (
    <>
      <Loading open={loadingOpen} />
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <h1>Preview prochain évenement</h1>
          <div className="next__event__container">
            {event.banner ? (
              <img
                className="next__event__img"
                src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${event.banner.imageName}`}
                alt={event.title}
              />
            ) : (
              ""
            )}
            <div className="next__event__info">
              <h3 className="next__event__eltitle">{event.title}</h3>
              <p className="next__event__elSlogan">{event.slogan}</p>
              <a
                className="next__event__btn"
                href={event.link}
                target="_blank"
                rel="noreferrer"
              >
                {event.campaign}{" "}
                <span
                  className="btn__angle__right"
                  style={{ color: `${event.colorHexa}` }}
                >
                  &#8250;
                </span>
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ShowNextEvent;
