import { useState, useEffect } from "react";
import { Grid, Paper, TextField, Button } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

import Loading from "../../../components/Loading";
import DialogAlert from "../../../components/DialogAlert";

import API from "../../../utils/API";

const EditDonor = () => {
  const { id } = useParams();
  const history = useHistory();

  const [donor, setDonor] = useState({
    name: "",
    email: "",
    password: "",
    passwordConfirm: "",

    fullName: "",
    firstName: "",
    lastName: "",
    address: "",
    country: "",
    city: "",
    zipCode: "",
    phoneNumber: "",
    company: "",
    state: "",
  });
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [alertmsg, setAlertmsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const handleAlertClose = (refresh) => {
    if (refresh) {
      setAlertOpen(false);
      history.push("/donors");
    }
    setAlertOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingOpen(true);
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/donors/${id}`
        );

        if (res.code === 200) {
          const data = res.data;

          setDonor({
            name: data.name,
            email: data.email,
            password: "",
            passwordConfirm: "",

            fullName: data.donor.fullName ? data.donor.fullName : "",
            firstName: data.donor.firstName ? data.donor.firstName : "",
            lastName: data.donor.lastName ? data.donor.lastName : "",
            address: data.donor.address ? data.donor.address : "",
            country: data.donor.country ? data.donor.country : "",
            city: data.donor.city ? data.donor.city : "",
            zipCode: data.donor.zipCode ? data.donor.zipCode : "",
            phoneNumber: data.donor.phoneNumber ? data.donor.phoneNumber : "",
            company: data.donor.company ? data.donor.company : "",
            state: data.donor.state ? data.donor.state : "",
          });
          setLoadingOpen(false);
        } else {
          setLoadingOpen(false);
          alert("Une erreur est survenue, contactez l'équipe dev");
        }
      } catch (error) {
        setLoadingOpen(false);
        console.log(error);
      }
    };
    fetchData();
    return () => {
      setDonor({
        name: "",
        email: "",
        password: "",
        passwordConfirm: "",

        fullName: "",
        firstName: "",
        lastName: "",
        address: "",
        country: "",
        city: "",
        zipCode: "",
        phoneNumber: "",
        company: "",
        state: "",
      });
    };
  }, [id]);

  const handleFormChange = (el, input) => {
    const inputValue = el.target.value;
    if (input === "email") {
      setDonor({ ...donor, email: inputValue });
    } else if (input === "password") {
      setDonor({ ...donor, password: inputValue });
    } else if (input === "passwordConfirm") {
      setDonor({ ...donor, passwordConfirm: inputValue });
    } else if (input === "firstName") {
      setDonor({
        ...donor,
        name: inputValue + " " + donor.lastName,
        fullName: inputValue + " " + donor.lastName,
        firstName: inputValue,
      });
    } else if (input === "lastName") {
      setDonor({
        ...donor,
        name: donor.firstName + " " + inputValue,
        fullName: donor.firstName + " " + inputValue,
        lastName: inputValue,
      });
    } else if (input === "address") {
      setDonor({ ...donor, address: inputValue });
    } else if (input === "country") {
      setDonor({ ...donor, country: inputValue });
    } else if (input === "city") {
      setDonor({ ...donor, city: inputValue });
    } else if (input === "zipCode") {
      setDonor({ ...donor, zipCode: inputValue });
    } else if (input === "phoneNumber") {
      setDonor({ ...donor, phoneNumber: inputValue });
    } else if (input === "company") {
      setDonor({ ...donor, company: inputValue });
    } else if (input === "state") {
      setDonor({ ...donor, state: inputValue });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (donor.passwordConfirm !== donor.password) {
      alert("Mot de passe et mot de passe confirme ne sont pas identiques");
      return false;
    }

    try {
      setLoadingOpen(true);
      const res = await API(
        "PUT",
        `${process.env.REACT_APP_API_BASE_URL}/donors/${id}`,
        donor
      );

      if (res.code === 200) {
        setLoadingOpen(false);
        setAlertOpen(true);
        setAlertmsg("Donateur modifié avec succées");
      } else {
        setLoadingOpen(false);
        alert("Verifiez les infos entrées");
      }
    } catch (error) {
      setLoadingOpen(false);
    }
  };
  return (
    <>
      <DialogAlert
        open={alertOpen}
        msg={alertmsg}
        handleClose={handleAlertClose}
      />
      <Loading open={loadingOpen} />
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={8} md={6}>
          <h1>Modifier un donateur</h1>
          <Paper className="paper__padding">
            <form onSubmit={handleFormSubmit}>
              <div className="form__control__custom">
                <TextField
                  autoComplete="off"
                  type="email"
                  id="donor_email"
                  name="donorEmail"
                  fullWidth
                  required
                  label="Email"
                  value={donor.email}
                  onChange={(e) => {
                    handleFormChange(e, "email");
                  }}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  autoComplete="off"
                  type="password"
                  id="donor_password"
                  name="donorPassword"
                  fullWidth
                  label="Mot de passe"
                  value={donor.password}
                  onChange={(e) => {
                    handleFormChange(e, "password");
                  }}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  autoComplete="off"
                  type="password"
                  id="donor_passwordConf"
                  name="donorPasswordConf"
                  fullWidth
                  label="Confirmer le mot de passe"
                  value={donor.passwordConfirm}
                  onChange={(e) => {
                    handleFormChange(e, "passwordConfirm");
                  }}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="donor_firstName"
                  name="donorFirstName"
                  fullWidth
                  label="Prénom"
                  value={donor.firstName}
                  onChange={(e) => {
                    handleFormChange(e, "firstName");
                  }}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="donor_lastName"
                  name="donorLastName"
                  fullWidth
                  label="Nom"
                  value={donor.lastName}
                  onChange={(e) => {
                    handleFormChange(e, "lastName");
                  }}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  type="tel"
                  id="donor_phoneNumber"
                  name="donorPhoneNumber"
                  fullWidth
                  label="Numéro de téléphone"
                  value={donor.phoneNumber}
                  onChange={(e) => {
                    handleFormChange(e, "phoneNumber");
                  }}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="donor_company"
                  name="donorCompany"
                  fullWidth
                  label="Raison sociale"
                  value={donor.company}
                  onChange={(e) => {
                    handleFormChange(e, "company");
                  }}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="donor_address"
                  name="donorAddress"
                  fullWidth
                  label="Adresse"
                  value={donor.address}
                  onChange={(e) => {
                    handleFormChange(e, "address");
                  }}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="donor_country"
                  name="donorCountry"
                  fullWidth
                  label="Pays"
                  value={donor.country}
                  onChange={(e) => {
                    handleFormChange(e, "country");
                  }}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="donor_city"
                  name="donorCity"
                  fullWidth
                  label="Ville"
                  value={donor.city}
                  onChange={(e) => {
                    handleFormChange(e, "city");
                  }}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="donor_state"
                  name="donorState"
                  fullWidth
                  label="Région"
                  value={donor.state}
                  onChange={(e) => {
                    handleFormChange(e, "state");
                  }}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  id="donor_zipCode"
                  name="donorZipCode"
                  fullWidth
                  label="Code postal"
                  value={donor.zipCode}
                  onChange={(e) => {
                    handleFormChange(e, "zipCode");
                  }}
                />
              </div>
              <div align="center">
                <Button variant="contained" color="primary" type="submit">
                  Modifier
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default EditDonor;
