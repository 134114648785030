import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Table from "../../components/Table";
import API from "../../utils/API";

const CollectMonth = () => {
  const [collects, setCollects] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    //fetch here
    const fetchData = async () => {
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/collects-month`
        );

        if (res.code === 200) {
          setCollects(res.data);
        } else {
          alert("Une erreur est survenue, contactez l'équipe dev");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    setColumns([
      { title: "Nom de la collecte", field: "name" },
      // { title: "Description", field: "description" },
      { title: "Lien de la collecte", field: "link" },
      { title: "Collectés", field: "collected" },
      { title: "Objectif", field: "goal" },
      { title: "Crée le", type: "date", field: "createdAt" },
    ]);

    return () => {
      setCollects([]);
      setColumns([]);
    };
  }, []);

  return (
    <div>
      <h1>Collecte du mois</h1>

      <div align="right" style={{ marginBottom: "20px" }}>
        <Link to="/collect-of-the-month/new" className="simple_link">
          <Button variant="contained" color="primary">
            Ajouter
          </Button>
        </Link>
      </div>
      <Table
        actions={["edit", "details", "delete"]}
        data={collects}
        columns={columns}
        tableTitle="collectes du mois"
        apiUrl={`${process.env.REACT_APP_API_BASE_URL}/collects-month`}
        baseUrl={"/collect-of-the-month/"}
      />
    </div>
  );
};

export default CollectMonth;
