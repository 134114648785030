import React from "react";
import {
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  IconButton,
  Hidden,
  Drawer,
  Divider,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import AppsIcon from "@material-ui/icons/Apps";

import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircle from "@material-ui/icons/AccountCircle";
// import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import EventIcon from "@material-ui/icons/Event";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { loggOut } from "../providers/actions";

import LifeLogo from "../assets/img/Logo-life-min.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    marginTop: "64px",
    padding: theme.spacing(3),
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
}));

const DefaultLayout = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { window, children } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenuAccount = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuAccountClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Typography
          variant="h6"
          noWrap
          style={{
            color: "#f36e20",
            width: "100%",
            textAlign: "center",
            height: "100px",
            display: "flex",
            fontSize: "32px",
          }}
        >
          <Link to="/" className={classes.link} style={{ margin: "auto" }}>
            <img
              src={LifeLogo}
              alt="LIFE"
              style={{ width: "70px", marginTop: "15px" }}
            />
          </Link>
        </Typography>
      </div>
      <Divider />
      <List>
        <Link to="/" className={classes.link}>
          <ListItem button key={"Dashboard"}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
        </Link>
      </List>

      <List>
        <Link to="/collect-of-the-month" className={classes.link}>
          <ListItem button key={"CollectOfTheMonth"}>
            <ListItemIcon>
              <MonetizationOnIcon />
            </ListItemIcon>
            <ListItemText primary={"Collecte du mois"} />
          </ListItem>
        </Link>
      </List>

      <List>
        <Link to="/next-event" className={classes.link}>
          <ListItem button key={"NextEvent"}>
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary={"Prochain événement"} />
          </ListItem>
        </Link>
      </List>

      <List>
        <Link to="/projects" className={classes.link}>
          <ListItem button key={"projects"}>
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary={"Projets"} />
          </ListItem>
        </Link>
      </List>

      <List>
        <Link to="/contact-requests" className={classes.link}>
          <ListItem button key={"contact"}>
            <ListItemIcon>
              <AllInboxIcon />
            </ListItemIcon>
            <ListItemText primary={"Contact"} />
          </ListItem>
        </Link>
      </List>

      <List>
        <Link to="/donors" className={classes.link}>
          <ListItem button key={"donors"}>
            <ListItemIcon>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText primary={"Donateurs"} />
          </ListItem>
        </Link>
      </List>

      {/* <List>
        <Link to="/settings" className={classes.link}>
          <ListItem button key={"Settings"}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={"Settings"} />
          </ListItem>
        </Link>
      </List> */}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap style={{ flexGrow: 1 }}></Typography>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenuAccount}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleMenuAccountClose}
            >
              <MenuItem
                onClick={() => {
                  dispatch(loggOut());
                  handleMenuAccountClose();
                }}
                style={{ width: "150px" }}
              >
                Logout
                <ExitToAppIcon
                  style={{ position: "absolute", right: "10px" }}
                />
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default DefaultLayout;
