import React, { useState, useEffect } from "react";
import { TextField, MenuItem, Grid, Paper, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import CircularProgress from "@material-ui/core/CircularProgress";

import DateFnsUtils from "@date-io/date-fns";
import API from "../../utils/API";
import emptyImage from "../../assets/img/empty-image.png";
import "./main.css";

const Form = ({ pageTitle, projectData }) => {
  const history = useHistory();
  const [searchKeyword, setSearchKeyword] = useState("");
  // const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [donor, setDonor] = useState(null);
  const [projectImagePreview, setProjectImagePreview] = useState(emptyImage);
  const [projectLivret, setProjectLivret] = useState("");
  const [projectMedias, setProjectMedias] = useState([]);
  const handleEndDateChange = (date) => {
    setData({ ...data, endDate: date });
    // setSelectedEndDate(date);
  };

  const [data, setData] = useState({
    projectId: "",
    projectName: "",
    projectDescription: "",
    projectAmount: "",
    projectStatus: "",
    endDate: null,
    projectStep: "",
    projectType: "",
    projectFinancement: "",
    wellDepth: "",
    projectLat: "",
    projectLong: "",
    projectTown: "",
    donorNamePlate: "",
    wellNamePlate: "",
    beneficiariesNumber: "",
    donorId: donor ? donor.id : "",
    mediaFolderUrl: "",
  });

  useEffect(() => {
    if (projectData && projectData.projectName) {
      setData(projectData);
      setProjectImagePreview(
        projectData.projectImage ? projectData.projectImage : emptyImage
      );
      setDonor(projectData.donorId);
      setProjectLivret(
        projectData.projectLivret ? projectData.projectLivret : ""
      );
      setProjectMedias(
        projectData.projectMedias ? projectData.projectMedias : []
      );
    } else {
      setData((d) => ({ ...d, projectAmount: 2500 }));
    }
    return () => {
      setData({
        projectId: "",
        projectName: "",
        projectDescription: "",
        projectAmount: "",
        projectStatus: "",
        endDate: null,
        projectStep: "",
        projectType: "",
        projectFinancement: "",
        wellDepth: "",
        projectLat: "",
        projectLong: "",
        projectTown: "",
        donorNamePlate: "",
        wellNamePlate: "",
        beneficiariesNumber: "",
        donorId: donor ? donor.id : "",
        mediaFolderUrl: "",
      });
    };
  }, [projectData, donor]);

  const handleFormChange = (event) => {
    if (event.target.name === "projectType") {
      if (event.target.value === "") {
        console.log(event.target.value);
        setData({
          ...data,
          [event.target.name]: event.target.value,
          projectAmount: 0,
        });
      } else if (event.target.value === "Borne Fontaine") {
        setData({
          ...data,
          [event.target.name]: event.target.value,
          projectAmount: 2500,
        });
      } else if (event.target.value === "Forage PMH") {
        setData({
          ...data,
          [event.target.name]: event.target.value,
          projectAmount: 2500,
        });
      } else if (event.target.value === "Forage Mini Tank") {
        console.log(event.target.value);
        setData({
          ...data,
          [event.target.name]: event.target.value,
          projectAmount: 2500,
        });
      } else if (event.target.value === "Château d'eau") {
        console.log(event.target.value);
        setData({
          ...data,
          [event.target.name]: event.target.value,
          projectAmount: 10000,
        });
      } else {
        setData({
          ...data,
          [event.target.name]: event.target.value,
          projectAmount: 2500,
        });
      }
    } else if (event.target.name === "projectImage") {
      if (event.target.files[0]) {
        const blobURL = URL.createObjectURL(event.target.files[0]);
        setProjectImagePreview(blobURL);
      } else {
        setProjectImagePreview(emptyImage);
      }
    } else if (event.target.name === "projectLivret") {
      if (event.target.files[0]) {
        const blobURL = URL.createObjectURL(event.target.files[0]);
        setProjectLivret(blobURL);
      } else {
        setProjectLivret("");
      }
    } else if (event.target.name === "projectMedias") {
      // do nothing
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };
  const [open, setOpen] = useState(false);

  const [donorsList, setDonorsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMedia, setLoadingMedia] = useState(false);

  useEffect(() => {
    var active = true;
    if (searchKeyword && searchKeyword.length >= 3) {
      (async () => {
        setLoading(true);
        const response = await API(
          "POST",
          `${process.env.REACT_APP_API_BASE_URL}/donors/search`,
          { keyword: searchKeyword }
        );
        if (response.code === 200) {
          if (active) {
            setDonorsList(response.data);
            setLoading(false);
          }
        }
      })();
    }

    return () => {
      setLoading(false);
      active = false;
    };
  }, [searchKeyword]);

  useEffect(() => {
    if (!open) {
      setDonorsList([]);
    }
  }, [open]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    var url = `${process.env.REACT_APP_API_BASE_URL}/projects`;
    var method = "POST";
    if (data.projectId) {
      url = `${process.env.REACT_APP_API_BASE_URL}/projects/${data.projectId}`;
    }

    const FD = new FormData(e.target);
    if (donor) {
      FD.append("donorId", donor.id);
    } else {
      FD.append("donorId", null);
    }
    if (projectMedias) {
      FD.append("projectMedias", JSON.stringify(projectMedias));
    }
    try {
      const response = await API(method, url, FD, "FormData");
      if (response.code === 200) {
        alert(response.msg);

        setLoading(false);
        history.push("/projects");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleMediaChange = async (e) => {
    console.log("FILES", e.target.files.length);

    for (let i = 0; i < e.target.files.length; i++) {
      const FD = new FormData();

      FD.append("file", e.target.files[i]);

      console.log(FD);

      // Upload files here
      try {
        setLoadingMedia(true);
        const response = await API(
          "POST",
          `${process.env.REACT_APP_API_BASE_URL}/upload-file`,
          FD,
          "FormData"
        );

        if (response.code === 200) {
          // set project media
          setProjectMedias((prev) => [
            ...prev,
            {
              url: `${process.env.REACT_APP_BASE_IMAGE_URL}/${response.data.imageName}`,
              id: response.data.id,
            },
          ]);

          setLoadingMedia(false);
        } else {
          alert("error occured when uploading file");
        }
        console.log("OK");
        setLoadingMedia(false);
      } catch (error) {
        console.log(error);
        alert("error occured when uploading file");
        setLoadingMedia(false);
      }
    }
  };

  const handleDeleteMedia = async (e, id) => {
    const res = window.confirm("Vous voulez vraiment supprimer cette image?");

    if (res) {
      // delete api here

      try {
        setLoadingMedia(true);
        const response = await API(
          "DELETE",
          `${process.env.REACT_APP_API_BASE_URL}/delete-file/${id}`
        );

        if (response.code === 200) {
          projectMedias.forEach((el) => {
            if (el.id === id) {
              var array = projectMedias;
              array.splice(array.indexOf(el), 1);
              setProjectMedias(array);
            }
          });
          setLoadingMedia(false);
          // e.target.parentNode
        }
      } catch (error) {
        console.log(error);
        setLoadingMedia(false);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item xs={12} md={6}>
            <h2>{pageTitle}</h2>
            <Paper className="paper__padding">
              <div className="form__control__custom">
                <TextField
                  id="project_name"
                  name="projectName"
                  fullWidth
                  required
                  label="Nom du projet"
                  value={data.projectName}
                  onChange={handleFormChange}
                />
              </div>
              <div className="form__control__custom">
                <TextField
                  type="number"
                  id="project_amount"
                  name="projectAmount"
                  fullWidth
                  required
                  label="Montant (€)"
                  value={data.projectAmount}
                  onChange={handleFormChange}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className="paper__padding" style={{ marginBottom: "20px" }}>
              <div className="form__control__custom">
                <TextField
                  id="description-project"
                  name="projectDescription"
                  label="Description"
                  multiline
                  fullWidth
                  rows={4}
                  value={data.projectDescription}
                  onChange={handleFormChange}
                />
              </div>
              <div className="form__control__custom">
                <p className="image__name__label">Image du projet</p>
                <label
                  align="center"
                  className="projectimage__preview__container"
                >
                  <input
                    type="file"
                    name="projectImage"
                    hidden
                    onChange={handleFormChange}
                    accept="image/*"
                  />
                  <img
                    src={projectImagePreview}
                    alt="preview"
                    className="imageProjectpreview"
                    title="Image du projet"
                  />
                </label>
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Paper className="paper__padding" style={{ marginBottom: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <div className="form__control__custom">
                <TextField
                  select
                  id="project_status"
                  name="projectStatus"
                  fullWidth
                  required
                  label="Status"
                  value={data.projectStatus}
                  onChange={handleFormChange}
                >
                  <MenuItem value="inprogress">En cours</MenuItem>
                  <MenuItem value="paused">En pause</MenuItem>
                  <MenuItem value="ended">Terminé</MenuItem>
                </TextField>
              </div>
            </Grid>

            <Grid item xs={12} md={4}>
              <div className="form__control__custom">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    style={{
                      marginTop: "unset",
                      marginBottom: "unset",
                      width: "100%",
                    }}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date d'inauguration"
                    value={data.endDate}
                    onChange={handleEndDateChange}
                    name="endDate"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="form__control__custom">
                <TextField
                  select
                  id="project_step"
                  name="projectStep"
                  fullWidth
                  label="Étape"
                  value={data.projectStep}
                  onChange={handleFormChange}
                >
                  <MenuItem value="aucune">Aucune</MenuItem>
                  <MenuItem value="donationSet">Don éffectué</MenuItem>
                  <MenuItem value="Diagnostic">Diagnostic</MenuItem>
                  <MenuItem value="villageAssignment">
                    Affectation village
                  </MenuItem>
                  <MenuItem value="startWorks">Début Travaux</MenuItem>
                  <MenuItem value="constructionProgress">
                    Construction en cours
                  </MenuItem>
                  <MenuItem value="wellFinished">Puits terminé</MenuItem>
                  <MenuItem value="livretReady">Livret disponible</MenuItem>
                </TextField>
              </div>
            </Grid>
          </Grid>
        </Paper>

        <Paper className="paper__padding" style={{ marginBottom: "20px" }}>
          <div align="center" className="form__control__custom">
            <Autocomplete
              id="combo-box-demo"
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              loading={loading}
              options={donorsList}
              getOptionLabel={(option) => option.name + " - " + option.email}
              getOptionSelected={(option, value) => option.name === value.name}
              value={donor}
              required
              onChange={(event, newValue) => {
                setDonor(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  helperText="Autocomplétion fonctionne qu'après avoir écrit 3 lettres, Vous devez sélectionner un parmis les donateurs dans la liste"
                  {...params}
                  label="Donateur"
                  value={searchKeyword}
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </Paper>

        <Paper className="paper__padding" style={{ marginBottom: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div className="form__control__custom">
                <TextField
                  select
                  id="project_type"
                  name="projectType"
                  fullWidth
                  required
                  label="Type de projet"
                  value={data.projectType}
                  onChange={handleFormChange}
                >
                  <MenuItem value="">A déterminer</MenuItem>
                  <MenuItem value="Forage PMH">Forage PMH</MenuItem>
                  <MenuItem value="Borne Fontaine">Borne Fontaine</MenuItem>
                  <MenuItem value="Château d'eau">Château d'eau</MenuItem>
                  <MenuItem value="Forage Mini Tank">Forage Mini Tank</MenuItem>
                </TextField>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="form__control__custom">
                <TextField
                  select
                  id="project_financement_type"
                  name="projectFinancement"
                  fullWidth
                  required
                  label="Type de financement"
                  value={data.projectFinancement}
                  onChange={handleFormChange}
                >
                  <MenuItem value="ponctuel">Ponctuel</MenuItem>
                  <MenuItem value="monthly">Mensuel</MenuItem>
                </TextField>
              </div>
            </Grid>

            <Grid item xs={12} md={4}>
              <div className="form__control__custom">
                <TextField
                  type="number"
                  id="project_depth"
                  name="wellDepth"
                  fullWidth
                  label="Profondeur du puit"
                  value={data.wellDepth}
                  onChange={handleFormChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="form__control__custom">
                <TextField
                  id="project_latitude"
                  name="projectLat"
                  label="Latitude"
                  value={data.projectLat}
                  onChange={handleFormChange}
                  style={{ width: "50%" }}
                />
                <TextField
                  id="project_depth"
                  name="projectLong"
                  label="Longitude"
                  value={data.projectLong}
                  onChange={handleFormChange}
                  style={{ width: "50%" }}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={4}>
              <div className="form__control__custom">
                <TextField
                  id="project_town"
                  name="projectTown"
                  fullWidth
                  label="Pays"
                  value={data.projectTown}
                  onChange={handleFormChange}
                />
              </div>
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <div className="form__control__custom">
                <TextField
                  id="project_town"
                  name="projectTown"
                  fullWidth
                  label="Village"
                  value={data.projectTown}
                  onChange={handleFormChange}
                />
              </div>
            </Grid> */}
            <Grid item xs={12} md={4}>
              <div className="form__control__custom">
                <TextField
                  id="project_plateDonorName"
                  name="donorNamePlate"
                  fullWidth
                  label="Nom Donateur (plaque)"
                  value={data.donorNamePlate}
                  onChange={handleFormChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="form__control__custom">
                <TextField
                  id="project_plateWellName"
                  name="wellNamePlate"
                  fullWidth
                  label="Nom du puit (plaque)"
                  value={data.wellNamePlate}
                  onChange={handleFormChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="form__control__custom">
                <TextField
                  type="number"
                  id="project_beneficiaries_number"
                  name="beneficiariesNumber"
                  fullWidth
                  label="Nombre de bénéficiaires"
                  value={data.beneficiariesNumber}
                  onChange={handleFormChange}
                />
              </div>
            </Grid>
          </Grid>
        </Paper>

        <Paper className="paper__padding" style={{ marginBottom: "20px" }}>
          <div className="form__control__custom">
            <TextField
              id="media_folder_url"
              name="mediaFolderUrl"
              fullWidth
              label="Mon projet en image (Url du dossier workdrive à partager)"
              value={data.mediaFolderUrl}
              onChange={handleFormChange}
            />
          </div>
        </Paper>

        <Paper className="paper__padding" style={{ marginBottom: "20px" }}>
          <div className="form__control__custom">
            <p className="image__name__label">Livret donateur</p>
            <label align="center" className="projectlivret__preview__container">
              <input
                type="file"
                name="projectLivret"
                onChange={handleFormChange}
                accept=".pdf"
              />
              {projectLivret ? (
                <iframe
                  title="RF"
                  id="pdfPreview"
                  width="100%"
                  height="400"
                  type="application/pdf"
                  style={{ border: "none", borderRadius: "4px" }}
                  src={projectLivret}
                >
                  <p>The PDF cannot be displayed.</p>
                </iframe>
              ) : (
                ""
              )}
            </label>
          </div>
        </Paper>

        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default Form;
