import React from "react";

import { Grid, IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";
import API from "../../../utils/API";

import { useHistory } from "react-router-dom";

import "./main.css";

import FormatDate from "../../../components/FormatDate";
import emptyImage from "../../../assets/img/emptyImage.png";

import donEffectueIcon from "../../../assets/img/projects/doneffectue.png";
import donEffectueSelectedIcon from "../../../assets/img/projects/doneffectueSelected.png";
import donEffectueActualIcon from "../../../assets/img/projects/doneffectueActual.png";

import diagnostiqueIcon from "../../../assets/img/projects/diagnostique.png";
import diagnostiqueSelectedIcon from "../../../assets/img/projects/diagnostiqueSelected.png";
import diagnostiqueActualIcon from "../../../assets/img/projects/diagnostiqueActual.png";

import affectationVillageIcon from "../../../assets/img/projects/affectationvillage.png";
import affectationVillageSelectedIcon from "../../../assets/img/projects/affectationvillageSelected.png";
import affectationVillageActualIcon from "../../../assets/img/projects/affectationvillageActual.png";

import debutTravauxIcon from "../../../assets/img/projects/debuttravaux.png";
import debutTravauxSelectedIcon from "../../../assets/img/projects/debuttravauxSelected.png";
import debutTravauxActualIcon from "../../../assets/img/projects/debuttravauxActual.png";

import constructionEnCoursIcon from "../../../assets/img/projects/constructionencours.png";
import constructionEnCoursSelectedIcon from "../../../assets/img/projects/constructionencoursSelected.png";
import constructionEnCoursActualIcon from "../../../assets/img/projects/constructionencoursActual.png";

import puitsTermineIcon from "../../../assets/img/projects/puitstermine.png";
import puitsTermineSelectedIcon from "../../../assets/img/projects/puitstermineSelected.png";
import puitsTermineActualIcon from "../../../assets/img/projects/puitstermineActual.png";


import livretreadyIcon from "../../../assets/img/projects/livretready.png";
import livretreadySelectedIcon from "../../../assets/img/projects/livretreadySelected.png";
import livretreadyActualIcon from "../../../assets/img/projects/livretreadyActual.png";


const ProjectCard = ({ project, reloadCallback }) => {
  let history = useHistory();
  function ParseFloat(str, val) {
    str = str.toString();
    str = str.slice(0, str.indexOf(".") + val + 1);
    return Number(str);
  }
  var pourcentage = 0;
  // const [stepsActive, setStepsActive] = useState({});
  const stepsActive = {
    donationSet: project.projectStep === "donationSet",
    Diagnostic: project.projectStep === "Diagnostic",
    villageAssignment: project.projectStep === "villageAssignment",
    startWorks: project.projectStep === "startWorks",
    constructionProgress: project.projectStep === "constructionProgress",
    wellFinished: project.projectStep === "wellFinished",
    livretReady: project.projectStep === "livretReady",
  };

  if (Object.values(stepsActive).includes(true)) {
    var ok = false;

    Object.keys(stepsActive).forEach((key, index) => {
      if (ok === false) {
        if (stepsActive[key] === true) {
          ok = true;
          pourcentage = ParseFloat((index + 1) * 14.2857142857, 2);
          if (pourcentage > 100) {
            pourcentage = 100;
          }
          if (project.projectStatus === "ended") {
            pourcentage = 100;
          }

          if (stepsActive.livretReady) {
            pourcentage = 100;
          }
        }
        stepsActive[key] = true;
      }
    });
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleModifClick = () => {
    history.push(`/projects/${project.id}/update`);
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = async () => {
    handleMenuClose();
    const msg = prompt(
      "Tapez 'YES' si vous voulez supprimer ce projet?",
      "YES"
    );
    if (msg === "YES") {
      try {
        const response = await API(
          "DELETE",
          `${process.env.REACT_APP_API_BASE_URL}/projects/${project.id}`
        );
        if (response.code === 200) {
          reloadCallback();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      <Grid container spacing={2} className="project__container">
        <div className="project__actions__btns">
          <IconButton edge="end" color="inherit" onClick={handleMenuOpen}>
            <MoreIcon />
          </IconButton>
          <Menu
            id="menu-actions"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <span href={`#`} className="simple_link">
              <MenuItem onClick={handleModifClick}>Modifier</MenuItem>
            </span>
            <MenuItem onClick={handleDeleteClick}>Supprimer</MenuItem>
          </Menu>
        </div>
        <Grid
          item
          lg={2}
          md={3}
          sm={12}
          xs={12}
          className="project__image_container"
        >
          <img
            src={
              project.photo
                ? process.env.REACT_APP_BASE_IMAGE_URL +
                  "/" +
                  project.photo.imageName
                : emptyImage
            }
            alt={project.name}
          />
        </Grid>
        <Grid item lg={10} md={9} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12} className="project__info_title_desc">
              <h3>
                {project.name}{" "}
                <span
                  className={
                    project.projectStatus === "ended"
                      ? "project__info__status__badge project__ended__bg"
                      : "project__info__status__badge"
                  }
                >
                  {project.projectStatus === "inprogress"
                    ? "EN COURS"
                    : project.projectStatus === "ended"
                    ? "TERMINÉ"
                    : project.projectStatus}
                </span>
              </h3>
              <p>{project.description}</p>
            </Grid>
            <Grid item md={6} xs={12} className="project__info_percentage">
              <p align="right" className="project__info__amount__collected">
                {/* <span>
                  {" "}
                  <b
                    className={
                      project.projectStatus === "ended"
                        ? "project__ended__color"
                        : ""
                    }
                  >
                    {project.amountCollected ?? 0} €
                  </b>{" "}
                  Collectés
                </span> */}
                <span className="project__info__amount__collected__percentage">
                  {pourcentage} % <br />
                </span>
              </p>

              <div className="project__progress_bar">
                <div
                  className={
                    project.projectStatus === "ended"
                      ? "project__progress project__ended__bg"
                      : "project__progress"
                  }
                  style={{
                    width: pourcentage + "%",
                  }}
                ></div>
              </div>
              {/* <p className="project__info__amount__goal">
                Objectif: {project.amountGoal ?? 0} €
              </p> */}
              {/* <span className="project__info__amount__collected__percentage">
                {pourcentage} %<br />
              </span> */}
            </Grid>
          </Grid>
          {project.projectStatus !== "ended" ? (
            <div className="project__status_line__cont">
              <Grid item md={12} className="project__status_line">
                <span
                  className="project__state__icon"
                  style={{ marginLeft: "calc(" + 100 / 8 + "% - 25px)" }}
                >
                  <img
                    src={
                      stepsActive.donationSet &&
                      project.projectStep === "donationSet"
                        ? donEffectueActualIcon
                        : stepsActive.donationSet
                        ? donEffectueSelectedIcon
                        : donEffectueIcon
                    }
                    alt="Don éffectué"
                    className={
                      stepsActive.donationSet &&
                      project.projectStep !== "donationSet"
                        ? "actualstep__dropShadow"
                        : ""
                    }
                  />
                  <br />
                  <b className="project__step__title">Don éffectué</b>
                </span>

                <span
                  className="project__state__icon"
                  style={{ marginLeft: "calc(" + (100 / 8) * 2 + "% - 25px)" }}
                >
                  <img
                    src={
                      stepsActive.Diagnostic &&
                      project.projectStep === "Diagnostic"
                        ? diagnostiqueActualIcon
                        : stepsActive.Diagnostic
                        ? diagnostiqueSelectedIcon
                        : diagnostiqueIcon
                    }
                    alt="Diagnostic"
                    className={
                      stepsActive.Diagnostic &&
                      project.projectStep !== "Diagnostic"
                        ? "actualstep__dropShadow"
                        : ""
                    }
                  />
                  <br />
                  <b className="project__step__title">Diagnostic</b>
                </span>

                <span
                  className="project__state__icon"
                  style={{ marginLeft: "calc(" + (100 / 8) * 3 + "% - 25px)" }}
                >
                  <img
                    src={
                      stepsActive.villageAssignment &&
                      project.projectStep === "villageAssignment"
                        ? affectationVillageActualIcon
                        : stepsActive.villageAssignment
                        ? affectationVillageSelectedIcon
                        : affectationVillageIcon
                    }
                    alt="Affectation Village"
                    className={
                      stepsActive.villageAssignment &&
                      project.projectStep !== "villageAssignment"
                        ? "actualstep__dropShadow"
                        : ""
                    }
                  />
                  <br />
                  <b className="project__step__title">Affectation Village</b>
                </span>

                <span
                  className="project__state__icon"
                  style={{ marginLeft: "calc(" + (100 / 8) * 4 + "% - 25px)" }}
                >
                  <img
                    src={
                      stepsActive.startWorks &&
                      project.projectStep === "startWorks"
                        ? debutTravauxActualIcon
                        : stepsActive.startWorks
                        ? debutTravauxSelectedIcon
                        : debutTravauxIcon
                    }
                    alt="Début Travaux"
                    className={
                      stepsActive.startWorks &&
                      project.projectStep !== "startWorks"
                        ? "actualstep__dropShadow"
                        : ""
                    }
                  />
                  <br />
                  <b className="project__step__title">Début Travaux</b>
                </span>

                <span
                  className="project__state__icon"
                  style={{ marginLeft: "calc(" + (100 / 8) * 5 + "% - 25px)" }}
                >
                  <img
                    src={
                      stepsActive.constructionProgress &&
                      project.projectStep === "constructionProgress"
                        ? constructionEnCoursActualIcon
                        : stepsActive.constructionProgress
                        ? constructionEnCoursSelectedIcon
                        : constructionEnCoursIcon
                    }
                    alt="Construction En Cours"
                    className={
                      stepsActive.constructionProgress &&
                      project.projectStep !== "constructionProgress"
                        ? "actualstep__dropShadow"
                        : ""
                    }
                  />
                  <br />
                  <b className="project__step__title">Construction En Cours</b>
                </span>

                <span
                  className="project__state__icon"
                  style={{ marginLeft: "calc(" + (100 / 8) * 6 + "% - 25px)" }}
                >
                  <img
                    src={
                      stepsActive.wellFinished &&
                      project.projectStep === "wellFinished"
                        ? puitsTermineActualIcon
                        : stepsActive.wellFinished
                        ? puitsTermineSelectedIcon
                        : puitsTermineIcon
                    }
                    alt="Puits Terminé"
                    className={
                      stepsActive.wellFinished &&
                      project.projectStep !== "wellFinished"
                        ? "actualstep__dropShadow"
                        : ""
                    }
                  />
                  <br />
                  <b className="project__step__title">Puits Terminé</b>
                </span>

                <span
                  className="project__state__icon"
                  style={{ marginLeft: "calc(" + (100 / 8) * 7 + "% - 25px)" }}
                >
                  <img
                    src={
                      stepsActive.livretReady &&
                      project.projectStep === "livretReady"
                        ? livretreadyActualIcon
                        : stepsActive.livretReady
                        ? livretreadySelectedIcon
                        : livretreadyIcon
                    }
                    alt="Puits Terminé"
                    className={
                      stepsActive.livretReady &&
                      project.projectStep !== "livretReady"
                        ? "actualstep__dropShadow"
                        : ""
                    }
                  />
                  <br />
                  <b className="project__step__title">Livret disponible</b>
                </span>
              </Grid>
            </div>
          ) : (
            <Grid container spacing={2} className="project__status_line_ended">
              <Grid
                item
                md={3}
                sm={6}
                xs={6}
                className="project___status__ended_details_cont"
              >
                Date d'inauguration
                <br />
                <b>
                  {project.endDate ? (
                    <FormatDate date={project.endDate.date} />
                  ) : (
                    ""
                  )}
                </b>
              </Grid>
              <Grid
                item
                md={3}
                sm={6}
                xs={6}
                className="project___status__ended_details_cont"
              >
                Beneficiaires
                <br />
                <b>
                  {project.beneficiairesNumber
                    ? project.beneficiairesNumber
                    : "."}
                </b>
              </Grid>
              <Grid
                item
                md={3}
                sm={6}
                xs={6}
                className="project___status__ended_details_cont"
              >
                Profondeur
                <br />
                <b>{project.projectDepth ? project.projectDepth : "."}</b>
              </Grid>
              <Grid
                item
                md={3}
                sm={6}
                xs={6}
                className="project___status__ended_details_cont"
              >
                Localisation
                <br />
                <b>
                  {project.locationLat ? project.locationLat : "."},{" "}
                  {project.locationLong ? project.locationLong : "."}
                </b>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectCard;
