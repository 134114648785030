import Form from "../Form";

const AddProject = () => {
  return (
    <>
      <Form pageTitle={"Nouveau projet"} />
    </>
  );
};

export default AddProject;
