import React, { forwardRef, useEffect, useState } from "react";
import MaterialTable from "material-table";
import API from "../../utils/API";
import { useHistory } from "react-router-dom";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Loading from "../Loading";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

const Table = (props) => {
  let history = useHistory();

  const {
    tableTitle,
    columns,
    data,
    actions,
    apiUrl,
    baseUrl,
    pageSize,
  } = props;

  const [actionsArray, setActionsArray] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTableData(data);
    let newActionArray = [];
    actions.forEach((action) => {
      if (action === "edit") {
        newActionArray.push({
          icon: forwardRef((props, ref) => (
            <EditIcon style={{ fontSize: "14px" }} {...props} ref={ref} />
          )),
          tooltip: "Modifier",
          onClick: (event, rowData) => {
            // Do save operation

            history.push(baseUrl + rowData.id + "/update");
          },
        });
      } else if (action === "details") {
        newActionArray.push({
          icon: forwardRef((props, ref) => (
            <VisibilityIcon style={{ fontSize: "14px" }} {...props} ref={ref} />
          )),
          tooltip: "Details",
          onClick: (event, rowData) => {
            history.push(baseUrl + rowData.id);
          },
        });
      } else if (action === "copy") {
        newActionArray.push({
          icon: forwardRef((props, ref) => (
            <FileCopyIcon style={{ fontSize: "14px" }} {...props} ref={ref} />
          )),
          tooltip: "Copier",
          onClick: (event, rowData) => {
            // Create a "hidden" input
            var aux = document.createElement("input");

            // Assign it the value of the specified element
            aux.setAttribute(
              "value",
              document.location.origin +
                "/payment/" +
                rowData.id +
                "/" +
                rowData.name
                  .replace(/\s/g, "-")
                  .replace(/\//g, "-")
                  .toLowerCase()
            );

            // Append it to the body
            document.body.appendChild(aux);

            // Highlight its content
            aux.select();

            // Copy the highlighted text
            document.execCommand("copy");

            // Remove it from the body
            document.body.removeChild(aux);
            alert("Product url Copied");
          },
        });
      } else if (action === "preview") {
        newActionArray.push({
          icon: forwardRef((props, ref) => (
            <OpenInNewIcon style={{ fontSize: "14px" }} {...props} ref={ref} />
          )),
          tooltip: "Visualiser",
          onClick: (event, rowData) => {
            window.open(
              "/payment/" +
                rowData.id +
                "/" +
                rowData.name
                  .replace(/\s/g, "-")
                  .replace(/\//g, "-")
                  .toLowerCase()
            );
            // history.push(
            //   "/payment/" + rowData.id + "/" + rowData.name.replace(/\s/g, "-")
            // );
          },
        });
      } else if (action === "delete") {
        newActionArray.push({
          icon: forwardRef((props, ref) => (
            <DeleteOutline style={{ fontSize: "14px" }} {...props} ref={ref} />
          )),
          tooltip: "Supprimer",
          onClick: async (event, rowData) => {
            // Do save operation
            let res = window.confirm(
              "Vous voulez vraiment supprimer cette enregistrement?"
            );
            if (res) {
              try {
                setIsLoading(true);
                const resp = await API("DELETE", `${apiUrl}/${rowData.id}`);
                setTableData(tableData.splice(rowData.tableData.id, 1));
                setIsLoading(false);
                alert(resp.msg);
              } catch (error) {
                console.error(error);
                setIsLoading(false);
              }
            }
          },
        });
      }
      // return action;
    });

    setActionsArray(newActionArray);
  }, [actions, apiUrl, data, tableData, history, baseUrl]);

  return (
    <>
      <Loading open={isLoading} />
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={tableData}
        title={tableTitle}
        options={{
          pageSize: pageSize ? pageSize : 10,
          pageSizeOptions: [5, 10, 20, 50],
          actionsColumnIndex: -1,
        }}
        actions={actionsArray}
        localization={{
          body: {
            emptyDataSourceMessage: "Pas d'enregistreent à afficher",
            addTooltip: "Ajouter",
            deleteTooltip: "Supprimer",
            editTooltip: "Editer",
            filterRow: {
              filterTooltip: "Filtrer",
            },
            editRow: {
              deleteText: "Voulez-vous supprimer cette ligne?",
              cancelTooltip: "Annuler",
              saveTooltip: "Enregistrer",
            },
          },
          grouping: {
            placeholder: "Tirer l'entête ...",
            groupedBy: "Grouper par:",
          },
          header: {
            actions: "Actions",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "lignes",
            labelRowsPerPage: "lignes par page:",
            firstAriaLabel: "Première page",
            firstTooltip: "Première page",
            previousAriaLabel: "Page précédente",
            previousTooltip: "Page précédente",
            nextAriaLabel: "Page suivante",
            nextTooltip: "Page suivante",
            lastAriaLabel: "Dernière page",
            lastTooltip: "Dernière page",
          },
          toolbar: {
            addRemoveColumns: "Ajouter ou supprimer des colonnes",
            nRowsSelected: "{0} ligne(s) sélectionée(s)",
            showColumnsTitle: "Voir les colonnes",
            showColumnsAriaLabel: "Voir les colonnes",
            exportTitle: "Exporter",
            exportAriaLabel: "Exporter",
            exportName: "Exporter en CSV",
            searchTooltip: "Chercher",
            searchPlaceholder: "Chercher",
          },
        }}
      />
    </>
  );
};

export default Table;
