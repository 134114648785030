import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useParams, useHistory } from "react-router";

import {
  FormControl,
  Input,
  InputLabel,
  IconButton,
  Button,
  InputAdornment,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

import API from "../../utils/API";
import SimpleTable from "../../components/SimpleTable";
import SimplePagination from "../../components/SimplePagination";

const recordPerPage = 10;

const tableDonorsCells = [
  {
    field: "name",
    label: "Nom complet",
  },
  {
    field: "email",
    label: "Email",
  },
  {
    field: "createdAt",
    label: "Crée le",
    type: "date",
  },
  {
    field: "actions",
    label: "Actions",
  },
];

const Donors = () => {
  let { page } = useParams();

  let history = useHistory();

  const [searchKey, setSearchKey] = useState("");
  const [resetSearch, setResetSearch] = useState(false);
  const [resetSearchVisible, setResetSearchVisible] = useState(false);

  const [donors, setDonors] = useState([]);

  const [pageNum, setPageNum] = useState(page ? page : 1);

  const [pagesTotal, setPagesTotal] = useState(1);

  useEffect(() => {
    //fetch here
    const fetchData = async () => {
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/donors?pageNum=${pageNum}&pageSize=${recordPerPage}`
        );

        if (res.code === 200) {
          res.data.forEach((donor) => {
            donor["actions"] = (
              <>
                <Link
                  to={`/donors/${donor.id}/update`}
                  style={{ color: "inherit" }}
                >
                  <IconButton aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </Link>

                <Link
                  to={`/donors/${donor.id}/details`}
                  style={{ color: "inherit" }}
                >
                  <IconButton aria-label="details">
                    <VisibilityIcon />
                  </IconButton>
                </Link>
              </>
            );
          });
          setDonors(res.data);

          setPageNum(res.pageNum);

          setPagesTotal(Math.ceil(res.total / recordPerPage));
        } else {
          alert("Une erreur est survenue, contactez l'équipe dev");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    return () => {
      setDonors([]);

      setPagesTotal(1);
    };
  }, [pageNum]);

  const handlePaginationCallback = (pageNumCallback) => {
    setPageNum(pageNumCallback);

    history.push(`/donors/${pageNumCallback}`);
  };

  const handleSearchClick = async (e) => {
    e.preventDefault();

    if (searchKey) {
      try {
        const res = await API(
          "POST",
          `${process.env.REACT_APP_API_BASE_URL}/donors/search`,
          { keyword: searchKey }
        );

        if (res.code === 200) {
          res.data.forEach((donor) => {
            donor["actions"] = (
              <>
                <Link
                  to={`/donors/${donor.id}/update`}
                  style={{ color: "inherit" }}
                >
                  <IconButton aria-label="edit">
                    <EditIcon />
                  </IconButton>
                </Link>

                <Link
                  to={`/donors/${donor.id}/details`}
                  style={{ color: "inherit" }}
                >
                  <IconButton aria-label="details">
                    <VisibilityIcon />
                  </IconButton>
                </Link>
              </>
            );
          });
          setDonors(res.data);
          setResetSearchVisible(true);
        } else {
          console.log("error", res);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleResetSearchClick = () => {
    setResetSearch(!resetSearch);
    setResetSearchVisible(false);
    setPageNum(page);
  };

  return (
    <div>
      <h1>Gestion des donateurs</h1>
      <div
        style={{
          marginBottom: "20px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          alignItems: "center",
        }}
      >
        <div align="left">
          <Link to="/donor/new" className="simple_link">
            <Button variant="contained" color="primary">
              Ajouter
            </Button>
          </Link>
        </div>
        <div align="right">
          <form onSubmit={handleSearchClick}>
            <FormControl>
              <InputLabel htmlFor="standard-adornment-search">
                Rechercher
              </InputLabel>
              <Input
                id="standard-adornment-search"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="Search" onClick={handleSearchClick}>
                      {<SearchIcon />}
                    </IconButton>
                    {resetSearchVisible ? (
                      <IconButton
                        aria-label="Reset Search"
                        onClick={handleResetSearchClick}
                      >
                        {<CloseIcon />}
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
        </div>
      </div>

      <div>
        <SimpleTable data={donors} tableCells={tableDonorsCells} />
        <SimplePagination
          pageNum={pageNum}
          pagesTotal={pagesTotal}
          paginationCallback={handlePaginationCallback}
        />
      </div>
    </div>
  );
};

export default Donors;
