import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Table from "../../components/Table";
import API from "../../utils/API";

const NextEvent = () => {
  const [events, setEvents] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    //fetch here
    const fetchData = async () => {
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/next-events`
        );

        if (res.code === 200) {
          setEvents(res.data);
        } else {
          alert("Une erreur est survenue, contactez l'équipe dev");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    setColumns([
      { title: "Titre de l'évenement ", field: "title" },
      { title: "Lien de l'évenement", field: "link" },
      { title: "Crée le", type: "date", field: "createdAt" },
    ]);

    return () => {
      setEvents([]);
      setColumns([]);
    };
  }, []);

  return (
    <div>
      <h1>Prochain évenement</h1>

      <div align="right" style={{ marginBottom: "20px" }}>
        <Link to="/next-event/new" className="simple_link">
          <Button variant="contained" color="primary">
            Ajouter
          </Button>
        </Link>
      </div>
      <Table
        actions={["edit", "details", "delete"]}
        data={events}
        columns={columns}
        tableTitle="Liste prochains événements"
        apiUrl={`${process.env.REACT_APP_API_BASE_URL}/next-events`}
        baseUrl={"/next-event/"}
      />
    </div>
  );
};

export default NextEvent;
