import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormatDate from "../FormatDate";
import CurrencyFormat from "../CurrencyFormat";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const SimpleTable = ({ data, tableCells }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {data.length && tableCells.length
              ? tableCells.map((col, index) => (
                  <TableCell key={index} style={customStyles.tableHeader}>
                    {col.label}
                  </TableCell>
                ))
              : null}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.length ? (
            data.map((row, index) => (
              <TableRow key={index}>
                {tableCells.map((col, index1) => {
                  const field = col.field;
                  const fields = field.split(".");

                  if (fields.length > 1) {
                    const field1 = fields[0];
                    const field2 = fields[1];
                    return (
                      <TableCell key={index1}>
                        {col.type === "date" ? (
                          <FormatDate date={row[field1][field2]} />
                        ) : col.type === "currency" ? (
                          <CurrencyFormat amount={row[field1][field2]} />
                        ) : (
                          row[field1][field2]
                        )}
                      </TableCell>
                    );
                  } else {
                    const field1 = fields[0];
                    return (
                      <TableCell key={index1}>
                        {col.type === "date" ? (
                          <FormatDate date={row[field1]} />
                        ) : col.type === "currency" ? (
                          <CurrencyFormat amount={row[field1]} />
                        ) : (
                          row[field1]
                        )}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            ))
          ) : (
            <TableRow align="center">
              <TableCell align="center">
                <b>No content</b>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;

const customStyles = {
  tableHeader: {
    textTransform: "capitalize",
    fontWeight: "bold",
  },
};
